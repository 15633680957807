<template>
  <div class="cpt-uav-warning-search">
    <el-form :model="search_form" class="linear-form">
      <el-form-item label="" style="flex: 1">
        <el-input
          v-model="search_form.keyword"
          size="mini"
          placeholder="请输入预警名称关键字"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <div @click="$emit('on-search', search_form)" class="linear-btn" style="padding: 0;">
          搜索
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search_form: {
        keyword: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.cpt-uav-warning-search {
  display: flex;
  ::v-deep {
    .linear-form {
      display: flex;
      width: 100%;
      .el-form-item__content {
        .el-input {
          .el-input__inner {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>