var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"title":"预警消息","titleCanMove":true,"isShowClose":true,"containerStyle":{
    width: '340px',
    top: '40px',
    right: '100px',
    zIndex: 2,
    position: 'absolute',
  }},on:{"onClose":_vm.close}},[_c('List',{attrs:{"dataSource":_vm.dataSource}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }