<template>
  <div class="cpt-self" v-interact="{ resize: [true, true, true, true], el: 3 }">
    <div class="hd">
      <div class="left ml8">
        <div class="title">平台起飞检查</div>
      </div>
      <!-- <div @click="$emit('close')" class="close">取消</div> -->
    </div>
    <div class="bd">
      <div class="time">
        <!-- 航线任务预估时长：{{ lineTime ? lineTime : "0时0分0秒" }} -->
        <!-- 航线任务预估时长：{{distanceLine ? distanceLine.toFixed(2) : 0}}slineTime -->
      </div>
      <div class="time mt10" style="color:red" v-if="!getUavDataDevice.isHomeLocationSet">起始点未定位</div>
      <div class="info">
        <div class="title" v-for="(item, i) in info" :key="i">
          <img v-if="item.warningLevel == 'NORMAL'" class="mr8" src="./assets/success.png" alt />
          <img v-if="item.warningLevel == 'WARNING'" class="mr8" src="./assets/error.png" alt />
          <img v-if="item.warningLevel == 'loading'" class="mr8" src="./assets/loading.png" alt />
          <div class="name">
            <div>{{ item.name }}</div>
            <div class="level" v-if="item.warningLevel == 'NORMAL' && item.type == 'BAT'">{{ item.title }}</div>
            <div class="level red" v-if="item.warningLevel == 'WARNING'">{{ "(" + item.title + ")" }}</div>
          </div>
        </div>
        <div class="tips">
          <div>提示:</div>
          <div>检查电池、防脱扣、机臂、桨叶、电调、挂载、外部螺丝是否安装到位无异常</div>
          <div>请确定外场飞手和无人机已保持安全距离</div>
        </div>
      </div>
      <div class="btns">
        <div class="btn btn1" @click="$emit('closeFlag')">取消</div>
        <div class="btn" v-if="!subflag" @click="takeOff">起飞</div>
        <div class="btn nbtn" v-else>起飞</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: [
        {
          type: "AVI",
          title: "正常",
          warningLevel: "loading",
          name: "航电系统"
        },
        {
          type: "SPEVPN",
          title: "正常",
          warningLevel: "loading",
          name: "公专网"
        },
        {
          type: "CHUTE",
          title: "正常",
          warningLevel: "loading",
          name: "降落伞"
        },
        {
          type: "OBS",
          title: "正常",
          warningLevel: "loading",
          name: "避障"
        },
        {
          type: "CONTROL",
          title: "已连接",
          warningLevel: "loading",
          name: "遥控器状态"
        },
        {
          type: "GPSNUM",
          title: "显示卫星数量",
          warningLevel: "loading",
          name: "GPS定位状态"
        },
        // {
        //   type: "TIME",
        //   title: "显示任务时常",
        //   warningLevel:"NORMAL",
        //   name:"航线任务预估时长"
        // },
        {
          type: "BAT",
          title: "实时电量显示",
          warningLevel: "loading",
          name: "智能电池"
        },
        // {
        //   name: "视频显示-正常",
        // },
        // {
        //   name: "提醒",
        // },
        // {
        //   name: "电池、防脱扣、机臂、桨叶、电调、挂载、外部螺丝安装到位无异常",
        // },
      ],
      heaArr: [],
      uavArr: [],
      batteryArr: [],
      time: 99999999999999999,//剩余电量
      batTitle: "",//电池正常，提示,
      index: 0,
      subflag: false,
      setTime: null,
      lineTime: ""
    };
  },
  computed: {
    getHealthData() {
      console.log(this.$store.state.mqtt.healthData, 'this.$store.state.mqtt.healthData');
      return this.$store.state.mqtt.healthData
    },
    getUavDataDevice() {
      return this.$store.state.mqtt.flyData
    },
    getBatteryData() {
      return this.$store.state.mqtt.getBattery
    }
  },
  props: {
    // distanceLine: {
    //   type: Number,
    //   default: 0
    // },
  },
  watch: {
    getBatteryData: {
      handler(val) {
        // console.log(val,"电池---eeeee");
        this.batteryPro(val);
      },
    },
    getHealthData: {
      handler(val) {
        this.healehPro(val);
        // console.log(val,"健康---eeeee");
      },
    },
    getUavDataDevice: {
      handler(val) {
        this.uavDataPro(val);
        // isRcConnecting遥控器
        // gps
      },
    },
    distanceLine: {
      // handler(val) {
      //   let h = parseInt(val / (60 * 60))
      //   let m = parseInt((val - h * 3600) / 60)
      //   let s = (val - h * 3600 - m * 60).toFixed(0)
      //   console.log(h, m, s);
      //   this.lineTime = h + "时" + m + "分" + s + "秒"
      //   this.info.forEach(item => {
      //     if (item.type == "TIME") {
      //       item.title = "任务预估时常" + val
      //     }
      //   })
      // }
    },
  },
  methods: {
    takeOff() {
      this.$emit('takeOff')
    },
    healehPro(val) {
      let arr = [];
      let arr2 = [];
      let svpn = null;

      for (let i in val) {
        //SPE专网  VPN公网
        if (i == "SPE" || i == "VPN") {
          let data = {
            type: i,
            warningLevel: val[i].warningLevel,
            title: val[i].title,
          };
          arr.push(data);
        }
        if (i != "SPE" && i != "VPN") {
          let data = {
            type: i,
            warningLevel: val[i].warningLevel == "WARNING" || val[i].warningLevel == "SERIOUS_WARNING" ? "WARNING" : "NORMAL",
            title: val[i].warningLevel == "WARNING" || val[i].warningLevel == "SERIOUS_WARNING" ? val[i].title : i == "BAT" ? this.batTitle : "正常",
          };
          arr2.push(data);
        }
      }
      if (arr.length == 0) {
        //公专网都不在
        svpn = {
          type: "SPEVPN",
          warningLevel: "WARNING",
          title: "更换起飞点或检查天线",
        };
      } else if (arr.length == 1) {
        //一个在线的预警
        if (
          arr[0].warningLevel == "WARNING" ||
          arr[0].warningLevel == "SERIOUS_WARNING"
        ) {
          svpn = {
            type: "SPEVPN",
            warningLevel: "WARNING",
            title: "更换起飞点或检查天线",
          };
        } else {
          // 另一个不在线，另一个在线正常
          if (arr[0].type == "VPN") {
            svpn = {
              type: "SPEVPN",
              warningLevel: "WARNING",
              title: "专网不在线",
            };
          } else {
            svpn = {
              type: "SPEVPN",
              warningLevel: "WARNING",
              title: "公网不在线",
            };
          }
        }
      } else {
        let flag = arr.every(
          (val) =>
            val.warningLevel == "WARNING" ||
            val.warningLevel == "SERIOUS_WARNING"
        );
        // 都在线，都预警
        if (flag) {
          svpn = {
            type: "SPEVPN",
            warningLevel: "WARNING",
            title: "更换起飞点或检查天线",
          };
        } else {
          let newArr = arr.filter((val) => {
            return (
              val.warningLevel == "WARNING" ||
              val.warningLevel == "SERIOUS_WARNING"
            );
          });
          // 都在线，但是一个预警
          if (newArr.length != 0) {
            svpn = {
              type: "SPEVPN",
              warningLevel: "WARNING",
              title: newArr[0].title,
            };
          } else {
            // 都正常
            svpn = {
              type: "SPEVPN",
              warningLevel: "NORMAL",
              title: "正常",
            };
          }
        }
      }
      // console.log(svpn,"svn----eeeeee");
      arr2.push(svpn);
      this.heaArr = arr2;
      // console.log(arr,"arr---eeeeee");
      // console.log(this.heaArr,"arr2---eeeeee");
    },
    uavDataPro(val) {
      let controlData = null;
      let gpsData = null;
      let arr = [];
      if (val.isRcConnecting) {
        controlData = {
          type: "CONTROL",
          warningLevel: "NORMAL",
          title: "已连接",
        };
      } else {
        controlData = {
          type: "CONTROL",
          warningLevel: "WARNING",
          title: "未连接",
        };
      }
      this.heaArr.forEach((item) => {
        // console.log(val.type);
        if (item.type == "GPS") {
          gpsData = {
            type: "GPSNUM",
            warningLevel: item.warningLevel,
            title: item.title,
            // num: val.gps.satelliteCount || 0,
          };
        }
      });
      arr.push(gpsData);
      arr.push(controlData);
      this.uavArr = arr;
      let uavArr = [];
      uavArr = this.heaArr.concat(this.uavArr);
      let uavArr2 = uavArr.concat(this.batteryArr)
      this.getList(uavArr2)
      // console.log(uavArr2,"gpsData---eeeeeee");
      // console.log(controlData,"controlData---eeeee");
    },
    batteryPro(val) {
      // console.log(val,"valeeeeeeeee");
      let batteryData = null;
      let arr = [];
      this.heaArr.forEach((item) => {
        if (item.type == "BAT" && item.warningLevel == "NORMAL") {
          console.log(this.time, val.timeRemaining, "eeeeeeee");
          // val.forEach((item) => {
          if (this.time > val.timeRemaining) {
            this.time = val.timeRemaining;
            this.batTitle = "(剩余飞行时间" + this.time / 60 + "分钟)"
            item.title = this.batTitle
          }
          // });
          // batteryData = {
          //   type: "BAT",
          //   title: "剩余飞行时间"+this.time,
          //   warningLevel:"NORMAL"
          // };
        }
      })
      // arr.push(batteryData);
      // this.batteryArr = arr;
      // console.log(arr, "arrr-----eeeeeeeeee");

    },
    getList(arr) {
      this.index = 0
      // console.log(arr);
      // let index = 0
      let _this = this
      function getInfo() {
        // if(this.index < _this.info.length){
        arr.forEach((item, i) => {
          if (item) {
            if (_this.info[_this.index] && _this.info[_this.index].type != undefined) {
              // console.log(_this.info[_this.index].type,"indexxxxxx");
              // console.log(item.type,_this.info[_this.index].type,"eeeeeee");
              if (item.type == _this.info[_this.index].type) {
                _this.info[_this.index].warningLevel = item.warningLevel
                _this.info[_this.index].title = item.title
              }
            }
          }
        })
        _this.index++
        // }
        // else{
        //   clearInterval(_this.setTime)
        // }
      }
      // if(arr.length == 10){
      this.setTime = setInterval(getInfo, 500)
      // }
      // setTimeout(() => {
      //   this.info.forEach((val,i)=>{
      //     arr.forEach((item)=>{
      //       if(item && val.type == item.type){
      //           val.warningLevel = item.warningLevel
      //         val.title = item.title
      //       }
      //     })
      //   })
      // }, 1000);
      this.subflag = this.info.some(item => item.warningLevel == "loading")
      // console.log(this.subflag,"subflag");
    },
  },
  mounted() {
    // let h = parseInt(this.distanceLine / (60 * 60))
    // let m = parseInt((this.distanceLine - h * 3600) / 60)
    // let s = (this.distanceLine - h * 3600 - m * 60).toFixed(0)
    // this.lineTime = h + "时" + m + "分" + s + "秒"
  },
  destroyed() {
    console.log(this.setTime);
    clearInterval(this.setTime)
    this.setTime = null
  }
};
</script>

<style lang="scss" scoped>
.cpt-self {
  position: fixed;
  top: calc(50% - 190px);
  right: calc(50% - 200px);
  background: rgba(0, 23, 79, 1);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  width: 550px;
  // height: 380px;
  box-sizing: border-box;
  // padding: 10px 20px;
  z-index: 40;

  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg,
            #e3aa77 0%,
            #f5cda9 38%,
            #f9ecd3 58%,
            #fcdbb1 79%,
            #edb07a 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    // justify-content: space-between;
    .time {
      color: #fff;
    }

    .info {
      display: flex;
      // justify-content: center;
      flex-wrap: wrap;

      // margin-left: 10px;
      .title {
        width: 160px;
        margin: 10px 0;
        margin-left: 35px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #ffffff;

        .level {
          white-space: nowrap;
          font-size: 14px;
          color: #ccc;
        }

        .red {
          color: red;
        }
      }

      .title:nth-of-type(2n) {
        margin-left: 150px;
      }

      .tips {
        margin-left: 35px;
        line-height: 20px;
        font-size: 12px;
        color: #fafafa;
      }
    }

    .btns {
      margin-top: 37px;
      display: flex;

      .btn {
        cursor: pointer;
        margin-left: 20px;
        padding: 7px 25px 6px 26px;
        // background: url("~@/assets/images/observe/twobg.png") no-repeat;
        border-radius: 4px;
        // border: 1px solid;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        background-color: blue;
        border-color: #fff;
      }

      .nbtn {
        cursor: no-drop;
      }

      .btn1 {
        border: solid 1px #ccc;
        background: transparent;
      }
    }
  }
}
</style>
