<template>
  <Dialog
    title="车流"
    :isShowClose="false"
    :containerStyle="{
      width: '416px',
      top: '40px',
      right: '27px',
      zIndex: 999,
    }"
  >
    <!-- <LiveNVRPlayer :dataSource="dataSource" /> -->
    <SRSPlayer :dataSource="dataSource" />
  </Dialog>
</template>

<script>
import Dialog from "@/components/common/dialog";
import LiveNVRPlayer from "@/components/device/player/livenvr";
import SRSPlayer from "@/components/device/player/srs";
export default {
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Dialog,
    LiveNVRPlayer,
    SRSPlayer,
  },
};
</script>

<style>
</style>