<template>
  <div class="cpt-warning-slot-box" @click="handle_viewer">
    <img ref="img" v-if="imgUrl" :src="imgUrl" alt="img" />
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
export default {
  props: {
    url: {
      type: String,
      default: () => null,
    },
    boxes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imgUrl: "",
    };
  },
  methods: {
    init_box() {
      const canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        image = new Image();

      image.setAttribute("crossOrigin", "Anonymous");
      image.src = this.url;
      image.onload = () => {
        let ratioX = 1,
          ratioY = 1;

        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);

        this.boxes.forEach((item) => {
          const { width: w, height: h, x, y } = item;

          const rx = x * ratioX,
            ry = y * ratioY,
            rw = w * ratioX,
            rh = h * ratioY;

          ctx.beginPath();
          ctx.lineWidth = 2;
          ctx.strokeStyle = "red";
          ctx.rect(rx, ry, rw, rh);
          ctx.stroke();
        });

        const base64 = canvas.toDataURL("image/jpeg", 1);
        this.imgUrl = base64;
      };
    },
    handle_viewer() {
      this.$viewerApi({
        images: [this.imgUrl],
      });
    },
  },
  mounted() {
    this.init_box();
  },
};
</script>

<style lang="scss" scoped>
.cpt-warning-slot-box {
  width: 124px;
  height: 80px;
  background: rgba(8, 45, 120, 0.25);
  border: 1px solid #488cff;
  box-sizing: border-box;
  margin-bottom: 5px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>