<template>
  <div class="cpt-app-uav-list">
    <div class="uav-item-box">
      <div
        v-if="
          data.goodsName == '入云龙' ||
          data.goodsName == '小旋风' ||
          data.goodsName == '单兵'
        "
        class="uav-item-inner"
        @click="data.collapse = !data.collapse"
      >
        <span
          class="el-icon-caret-right"
          :class="{ collapse: data.collapse }"
        ></span>
        <div class="org-name" :title="data.goodsName">{{ data.goodsName }}</div>
        <div class="online-info"></div>
      </div>
      <div
        v-else
        class="uav-item-inner"
        @click="data.collapse = !data.collapse"
      >
        <span
          class="el-icon-caret-right"
          :class="{ collapse: data.collapse }"
        ></span>
        <div class="org-name" :title="data.anotherName">
          {{ data.anotherName }}
        </div>
        <div class="online-info">
          （ 共 {{ data.offline + data.online }} 架
          <span class="ml10" :class="{ online: true }">{{ data.online }}</span
          >在线 / <span class="ml2">{{ data.offline }} </span> 离线）
        </div>
      </div>
      <div class="uav-item-child-box" :class="{ collapse: data.collapse }">
        <template v-for="child in data.child">
          <Item v-if="child" :key="child.id" :data="child" />
        </template>
        <template>
          <div v-if="data.devices.length > 0">
            <div
              v-for="device in data.devices"
              :key="`device_${device.id}`"
              class="device-item-box"
              :class="{ online: device.reportStatus == 1 }"
            >
              <div
                :class="device.reportStatus == 1 ? 'color0F' : ''"
                class="device-name jcsb"
                :title="device.deviceName"
              >
                <span class="name-value">
                  {{ device.deviceName }}
                </span>
                <span v-if="device.deviceHardId">
                  （<span v-if="device.reportStatus === 1" class="ys"
                    >在线</span
                  >
                  <span v-if="device.reportStatus === 0">离线</span>）
                </span>
              </div>
              <div class="device-fns">
                <div
                  class="iconfont icon-dingwei"
                  v-if="haves.includes(1) && data.goodsName != '单兵'"
                  :class="{ active: device._location }"
                  title="定位"
                  @click="fn(1, device)"
                ></div>

                <div
                  class="iconfont icon-zhibo"
                  :class="{
                    active: device._video,
                    videoStatus: device._videoStatus,
                  }"
                  title="视频"
                  @click="fn(3, device)"
                  v-if="haves.includes(2)"
                ></div>
                <div
                  class="iconfont icon-kongzhitai"
                  v-if="haves.includes(3) && data.goodsName == '入云龙'"
                  :class="{ active: device._control }"
                  title="控制面板"
                  @click="fn(4, device)"
                ></div>
                <span
                  title="任务详情"
                  v-if="haves.includes(4) && data.goodsName == '入云龙'"
                  :class="{ active: device._airway }"
                  class="iconfont icon-renwubaogao"
                  @click="fn(2, device)"
                ></span>
              </div>
            </div>
          </div>
          <div v-else>
            <template v-for="child_ in data.deviceTypes">
              <Item v-if="child_" :key="child_.id" :data="child_" />
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Item",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ["_uavThis", "task_This"],
  data() {
    return {
      haves: [],
    };
  },
  created() {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("hn:tasks:control:location") > -1) {
      this.haves.push(1);
    }
    if (permissionList.indexOf("hn:tasks:control:video") > -1) {
      this.haves.push(2);
    }
    if (permissionList.indexOf("hn:tasks:control:controlPanel") > -1) {
      this.haves.push(3);
    }
    if (permissionList.indexOf("hn:tasks:control:taskDetails") > -1) {
      this.haves.push(4);
    }
  },
  mounted() {
    if (this.data.deviceTypes && this.data.deviceTypes.length > 0) {
      let data = this.data.deviceTypes;
      console.log(data, "data.length");
      for (let i = 0; i < data.length; i++) {
        const el = data[i];
        if (el.goodsName == "入云龙") {
          for (let k = 0; k < el.devices.length; k++) {
            const device = el.devices[k];
            this._uavThis.handle_location(device, true);
          }
        }
      }
    }
    // for (let i = 0; i < this.data.devices.length; i++) {
    //   const el = this.data.devices[i];
    //   if (el.goodsName == "无人机") {
    //     console.log(el, "element");
    //   }
    // }
  },
  methods: {
    fn(item, device) {
      // if (item == 4 && device.reportStatus == 0) {
      //   return this.$message.warning("无人机离线中");
      // }
      if (item == 1) {
        this._uavThis.handle_location(device);
      } else if (item == 4) {
        //保存数据
        this.$store.commit("mqtt/setState", {
          key: "uav",
          value: device,
        });
        this._uavThis.handle_control(device);
      } else if (item == 3) {
        this.$emit("on-video", device);
        this._uavThis.handle_video(device);
      } else if (item == 2) {
        this.task_This.showDetailsInfo(device.deviceHardId, device);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.color0F {
  color: #00ffff;
}

.cpt-app-uav-list {
  .el-switch__core {
    border: #01ffff;
  }

  overflow: auto;

  .uav-item-box {
    font-family: Microsoft YaHei;
    font-size: 16px;
    color: #fff;
    font-weight: 400;

    .uav-item-inner {
      height: 38px;
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .el-icon-caret-right {
        cursor: pointer;
        transition: 0.3s;
        transform-origin: center;
        transform: rotate(90deg);

        &.collapse {
          transform: rotate(0deg);
        }
      }

      .online-info {
        white-space: nowrap;

        .online {
          color: #00ffff;
        }
      }

      .org-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover {
        height: 38px;
        background: rgba(2, 19, 96, 0.2);
        box-shadow: inset 0px 0px 10px 2px #3fcbff;
        border: 1px solid #70f6f9;
      }
    }

    .uav-item-child-box {
      padding-left: 20px;
      transition: 0.3s;
      overflow: hidden;
      position: relative;

      &::before {
        content: " ";
        display: block;
        width: 1px;
        background-color: #129c9c;
        position: absolute;
        left: 7px;
        top: 0;
      }

      &.collapse {
        max-height: 0;
      }

      .device-item-box {
        height: 38px;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        transition: 0.2s;

        .device-name {
          width: calc(100% - 150px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          .name-value {
            width: 70%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &.online {
          .device-name {
            color: #00ffff;
          }
        }

        .device-fns {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .iconfont {
            font-size: 24px;
            margin-right: 8px;
            cursor: pointer;

            &.videoStatus {
              color: #4fbc6a;
            }

            &.active {
              color: #00ffff;
            }
          }
        }

        &:hover {
          height: 38px;
          background: rgba(2, 19, 96, 0.2);
          box-shadow: inset 0px 0px 10px 2px #3fcbff;
          border: 1px solid #70f6f9;
        }
      }
    }
  }
}
</style>
