<template>
  <div class="cpt-device-mount-booth">
    <div class="swiper mount-swiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide cpt-device-mount"
          v-for="(mount, index) in dataSource"
          :key="index"
          @click="$emit('on-change', mount)"
        >
          <img :src="mount.icon" class="device-mount-icon" alt="mount" />
          <div class="device-mount-cover">
            {{ mount.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper.min.css";

export default {
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper(".mount-swiper", {
        slidesPerView: "auto",
        // centeredSlides: true,
        spaceBetween: 5,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.cpt-device-mount-booth {
  box-sizing: border-box;
  display: flex;
  .mount-swiper {
    height: 120px;
    width: 100%;
    .cpt-device-mount {
      user-select: none;
      cursor: pointer;
      flex-shrink: 0;
      width: 92px;
      height: 120px;
      box-sizing: border-box;
      background-image: linear-gradient(
        180deg,
        rgba(50, 117, 222, 0.2) 0%,
        rgba(67, 222, 255, 0.4) 52%,
        rgba(50, 117, 222, 0.2) 100%
      );
      border: 1px solid rgba(132, 178, 255, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .device-mount-icon {
        width: 80px;
        height: 80px;
      }
      .device-mount-cover {
        height: 24px;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        font-size: 12px;
        color: #9fc4ff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>