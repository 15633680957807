<template>
  <div class="page-platform-visual">
    <!-- <div class="cesium-container" >
        <div class="cesium-wrap">
          <CesiumLayer ref="cesiumLayer"/>
        </div>
      </div> -->
    <!-- 左上任务列表 -->
    <UAVTask
      @on-location="handle_location"
      @on-control="handle_control"
      @on-video="handle_video"
      @on-report="handle_report"
      @upOnList="upOnList"
      @chageVdieoPost="chageVdieoPost"
      :float_videos="float_videos"
      :uav_control="uav_control"
      ref="one"
    />
    <UAVTaskList
      ref="uavTaskList"
      @selfTest="selfTestFlag = true"
      v-if="uavTaskListFlag && uav_control.stationType == 1"
    >
    </UAVTaskList>
    <template v-if="uav_control && uav_control.deviceHardId">
      <!-- 挂载面板 -->
      <!-- <UAVMount @on-change="handle_change_mount" /> -->

      <!-- <UAVVideo :dataSource="uav_video" /> -->

      <!-- 下方控制器 -->
      <UAVDashboard
        :batteryData="batteryData[uav_control.deviceHardId]"
        :goodsName="uav_control.goodsName"
        @fn="control_ws"
        :data="uav_data[uav_control.deviceHardId]"
      />
    </template>

    <template v-if="selected_mount">
      <!-- 挂载详情面板 -->
      <Dialog
        :isShowClose="false"
        ref="move"
        :title="selected_mount.title"
        :containerStyle="{
          width: `${uav_width}`,
          top: '266px',
          right: '96px',
          zIndex: 30,
        }"
      >
        <div class="movediv" @mouseenter="mouseDown" @mouseleave="dragmove">
          <div
            @click="closeMountDetails"
            class="iconfont icon-guanbi close"
          ></div>
        </div>
        <component
          :is="selected_mount.component"
          :whachChange="whachChange"
          @directive="handle_mount_directive"
          @width="boxWidth"
        />
      </Dialog>
    </template>

    <!-- 无人机视频播放器 -->
    <FloatPlayer
      v-for="(item, index) in float_videos"
      :key="item.deviceHardId"
      :id="item.id"
      :title="item.title"
      :selsectAi="item.ai"
      :dataSource="item.dataSource"
      :deviceHardId="item.deviceHardId"
      @on-viewClose="close_video"
      @isSelectedShdow="isSelectedShdow"
      :videoPostion="videoPostion"
      :videoPosRight="videoPosRight[index]"
      :videoPosLeft="videoPosLeft[index]"
      :boxShadow="boxShadow[index]"
      class="float_videos_box"
    />
    <!-- 右侧预警框 -->
    <Warning
      v-if="showWarning"
      :onLineList="onLineList"
      @onclose="showWarning = false"
    />
    <!-- 右侧工具栏 -->
    <FiexdRight>
      <template slot="alertMessage">
        <div class="w65 h65 cp" @click="showWarning = !showWarning">
          <el-tooltip content="预警" placement="left" effect="dark">
            <img class="w65 h65 dib" :src="alertMessage" />
          </el-tooltip>
        </div>
      </template>
    </FiexdRight>

    <!-- 右上菜单栏 -->
    <FlexRightMenu
      @fn="control_ws"
      @on-change="handle_change_mount"
      v-if="
        uav_control &&
        uav_control.deviceHardId &&
        uav_control.goodsName != '小旋风'
      "
      ref="menuClose"
    />
    <!-- 切换地图组件（2D/街道） -->
    <transition
      name="animate__animated dh"
      key="1"
      enter-active-class="animate__fadeInUp"
      leave-active-class="animate__fadeOutDown"
    >
      <Bottomright />
    </transition>
    <Logger @exit="loggerFlag = false" v-if="loggerFlag"></Logger>
    <!-- 安全检查 -->
    <selfTest
      @closeFlag="selfTestFlag = false"
      @takeOff="takeOff_"
      v-if="selfTestFlag"
    ></selfTest>
  </div>
</template>

<script>
import alertMessage2 from "@/assets/images/menu/alertMessage2.png";
import alertMessage3 from "@/assets/images/menu/alertMessage3.png";
import alertMessage from "@/assets/images/menu/alertMessage.svg";

import CesiumLayer from "@/components/cesium-layer";
import FiexdRight from "@/components/fiexdright";
import FlexRightMenu from "@/components/flexrightmenu";
import interact from "interactjs";
import Bottomright from "@/components/Bottomright"; //切换地图组件（2D/街道）
let ws;
let x = 0; //  x轴的移动大小
let y = 0; //  y轴的移动大小
let styleStr = "zIndex: 999;transition: all 300ms linear;position: absolute;";
import { mapGetters } from "vuex";
import Dialog from "@/components/common/dialog";
import API from "@/api";

import UAVTask from "./uav/task";
import selfTest from "./uav/selfTest/index.vue";
import UAVTaskList from "./uav/taskList";
import UAVMount from "./uav/mount";
import UAVVideo from "./uav/video";
import UAVDashboard from "./uav/dashboard";
import FloatPlayer from "./uav/float-player";
import Logger from "./uav/logger";

import Warning from "./uav/warning";
import mqtt from "mqtt";
const { VUE_APP_WS_URL: WS_URL } = process.env;
const { VUE_APP_FKMTQQ: FK_URL } = process.env;

import { nanoid } from "nanoid";
let entityList = [];
export default {
  components: {
    UAVTask,
    UAVMount,
    UAVVideo,
    UAVDashboard,
    Dialog,
    FloatPlayer,
    Warning,
    FiexdRight,
    FlexRightMenu,
    CesiumLayer,
    Bottomright,
    UAVTaskList,
    Logger,
    selfTest,
  },
  data() {
    return {
      loggerFlag: false,
      uavTaskListFlag: false,
      whachChange: 0,
      uav_control: {},
      uav_video: [],
      uav_data: [],
      selfTestFlag: false,
      batteryData: {}, //电池数据
      uav_width: "500px",
      isShow: false,
      showWarning: false,
      selected_mount: null,
      hId: "",

      float_videos: [],
      uavMap: {},
      onLineList: null,
      alertMessage2,
      alertMessage3,
      alertMessage,
      videoPostion: true, //视频框默认位置
      videoPosRight: [
        `top: 99px;left: 548px;${styleStr}`,
        `top: 99px;left: 977px;${styleStr}`,
        `top: 99px;left: 1400px;${styleStr}`,
        `top: 373px;left: 548px;${styleStr}`,
        `top: 373px;left: 977px;${styleStr}`,
        `top: 373px;left: 1400px;${styleStr}`,
      ],
      // 关闭视频窗口时候 视频补齐位置动画
      videoPosLeft: [
        `top: 99px;left: 548px;${styleStr}`,
        `top: 99px;left: 977px;${styleStr}`,
        `top: 99px;left: 1400px;${styleStr}`,
        `top: 373px;left: 548px;${styleStr}`,
        `top: 373px;left: 977px;${styleStr}`,
        `top: 373px;left: 1400px;${styleStr}`,
      ],
      boxShadow: [],
      boxShadowStatus: false, //标记是否点击同一个视频拥有高亮
      // mqtt start
      mqttClient: null,
      mqttOptions: {
        clientId: "mqttClient",
      },
      nestPositions: [],
      reconnectInterval: 5000, // 重连间隔时间（毫秒）
      reconnectTimer: null, // 重连定时器
      oldId: null,
      // 鹰巢设备
      yc_deviceSerial: null,
      // mqtt end
    };
  },

  watch: {
    selected_mount(val) {
      if (val) {
        this.$nextTick(() => {
          this.moveElement();
        });
      }
    },
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  methods: {
    async getYcId() {
      let { data } = await API.POI.List();
      this.yc_deviceSerial = data.map((item) => {
        return item.deviceSerial;
      });
    },
    // 一键任务
    takeOff_() {
      this.selfTestFlag = false;
      if (this.$refs.uavTaskList) {
        console.log(this.$refs.uavTaskList, "this.$refs.uavTaskList");
        this.$refs.uavTaskList.takeOff();
      }
    },
    // 列表挂载面板点击方法
    handle_control(row) {
      this.$set(this, "uav_control", { deviceHardId: null });
      row._control = !row._control;
      if (this.oldId && this.oldId != row.id) {
        this.uavTaskListFlag = false;
        // 清除上一个控制台状态
        this.$refs.one.changeStatus(this.oldId, "_control", false);
      }
      this.oldId = row.id;
      this.$set(this, "uav_control", { deviceHardId: null });
      this.$store.commit("device/SET_deviceHardId", row.deviceHardId);
      let topic = `PX4/RECEIVE/${row.deviceHardId}`;
      if (row._control) {
        this.uavTaskListFlag = true;
        if (row.goodsName == "小旋风") {
          this.mqttClient.subscribe(topic);
        }
        setTimeout(() => {
          this.$set(this, "uav_control", row);
        }, 10);
        this.$store.dispatch("mqtt/connect", {
          callback: (isOk) => {
            isOk && console.log("mqtt连接成功!");
          },
        });
      } else {
        this.uavTaskListFlag = false;
        if (row.goodsName == "小旋风") {
          this.mqttClient.unsubscribe(topic);
        }
      }
    },
    // 列表定位方法
    async handle_location(row, key) {
      let hId = row.deviceHardId;
      // 判断地图是否有飞机模型

      let entitystate = viewer.entities.getById(hId);
      if (entitystate) {
        if (!row._location) {
          console.log(!row._location, "!row._location");
          row._location = true;
          viewer.camera.flyTo({
            destination: entitystate.position.getValue(Cesium.JulianDate.now()),
            maximumHeight: 100,
            complete: () => {
              // 锁定
              viewer.trackedEntity = entitystate;
            },
          });
          entitystate.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
          return;
        }
        row._location = false;
        // 根据传递的num类型判断是取消还是定位， 1是定位
        viewer.entities.remove(entitystate);
      } else {
        if (row.goodsName != "小旋风") {
          let uav_data = await API.UAV.GetLastUavData({
            deviceHardId: row.deviceHardId,
          });
          if (uav_data.data) {
            if (!key) {
              row._location = !row._location;
            }
            uav_data = uav_data.data;
            uav_data.uavDeviceID = row.deviceHardId;
            uav_data.uavDeviceName = row.deviceName;
            const data = this.UAVDataParser(uav_data);
            data.goodsName = row.goodsName;
            if (!this.uavMap[hId]) {
              this.uavMap[hId] = {
                position: null,
                orientation: null,
                positions: [],
              };
            }

            if (data.position) {
              this.uavMap[hId].position = data.position;
              this.uavMap[hId].orientation = data.orientation;
              this.uavMap[hId].positions.push(data.position.clone());
              const entity = this.createUAVModel(data);
              entityList.push(entity);
              if (entity) {
                if (!key) {
                  viewer.camera.flyTo({
                    destination: entity.position.getValue(
                      Cesium.JulianDate.now()
                    ),
                    maximumHeight: 100,
                    complete: () => {
                      // 锁定
                      viewer.trackedEntity = entity;
                    },
                  });
                  entity.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
                }
              }
            }
          } else {
            if (!key) {
              this.$message.error("暂无实时数据");
            }
          }
        } else {
          row._location = !row._location;
          let deviceHardId = row.deviceHardId;
          if (!this.uavMap[deviceHardId]) {
            this.uavMap[deviceHardId] = {};
            this.uavMap[deviceHardId].positions = [];
            let position = Cesium.Cartesian3.fromDegrees(
              row.longitude * 1,
              row.latitude * 1,
              row.altitude * 1
            );
            this.uavMap[deviceHardId].position = position;
            this.uavMap[deviceHardId].orientation = {
              x: -0.19003559033482414,
              y: -0.41505906841793716,
              z: -0.5989978996549129,
              w: 0.6578859782204907,
            };
            this.uavMap[deviceHardId].positions.push(position);
          }
          let data = {
            name: row.deviceName,
            hId: row.deviceHardId,
            goodsName: row.goodsName,
          };
          let topic = `PX4/RECEIVE/${row.deviceHardId}`;
          this.mqttClient.subscribe(topic);
          const entity = this.createUAVModel(data);
          entityList.push(entity);

          if (entity) {
            viewer.camera.flyTo({
              destination: entity.position.getValue(Cesium.JulianDate.now()),
              maximumHeight: 100,
              complete: () => {
                // 锁定
                viewer.trackedEntity = entity;
              },
            });
            entity.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
          }
        }
      }
    },
    connectMqtt() {
      // 在这里可以订阅主题
      this.mqttOptions.clientId = "key" + nanoid();
      this.mqttClient = mqtt.connect(FK_URL, this.mqttOptions);
      this.mqttClient.on("connect", () => {
        console.log("Connected to MQTT server");
      });

      this.mqttClient.on("message", (topic, payload) => {
        const data = JSON.parse(payload.toString());
        let newtopic = topic;
        let deviceId = newtopic.replace("PX4/RECEIVE/", "");
        // let deviceHardId = deviceId
        // if (data.type == 258) {
        //   let attitude = data.data.attitude
        //   let homeLocation = data.data.homeLocation || data.data.locationCoordinate3D
        //   if (!this.uavMap[deviceHardId]) {
        //     this.uavMap[deviceHardId] = {};
        //     this.uavMap[deviceHardId].positions = [];
        //   }
        //   if (homeLocation) {
        //     let position = Cesium.Cartesian3.fromDegrees(
        //       homeLocation.longitude * 1,
        //       homeLocation.latitude * 1,
        //       homeLocation.altitude * 1
        //     );
        //     this.uavMap[deviceHardId].position = position;

        //     let yaw = Cesium.Math.toRadians(attitude.yaw + 90),
        //       pitch = Cesium.Math.toRadians(attitude.pitch),
        //       roll = Cesium.Math.toRadians(attitude.roll),
        //       hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll);
        //     let orientation = Cesium.Transforms.headingPitchRollQuaternion(
        //       position,
        //       hpr
        //     );
        //     this.uavMap[deviceHardId].orientation = orientation;
        //     this.uavMap[deviceHardId].positions.push(JSON.parse(JSON.stringify(position)));
        //     // this.nestPositions.push(JSON.parse(JSON.stringify(position)))
        //   }

        //   if (!this.uav_data[deviceHardId]) {
        //     this.uav_data[deviceHardId] = {}
        //   }
        //   let uav_data = {
        //     uavInfo: {
        //       roll: attitude.roll,
        //       pitch: attitude.pitch,
        //       height: data.data?.gps.absoluteAlt || 0,
        //       flightDistance: data.data.flyDistance,
        //       groundSpeed: data.data.groundSpeed,
        //       flightTime: data.data.flyTime,
        //       climbRate: data.data.velocityZ,
        //       voltage: 27
        //     }
        //   }
        //   this.uav_data[deviceHardId] = uav_data
        //   this.$forceUpdate()
        // }
        // if (data.type == 257) {
        //   if (!this.batteryData[deviceHardId]) {
        //     this.batteryData[deviceHardId] = []
        //   }
        //   this.batteryData[deviceHardId] = data.data
        //   this.$forceUpdate()
        // }
      });

      this.mqttClient.on("error", (error) => {
        console.error("MQTT Error:", error);
        // 在连接出现错误时处理错误
      });

      this.mqttClient.on("close", () => {
        console.log("MQTT Connection closed");
        // 连接关闭时触发，可以在这里进行重连
        // this.reconnect();
      });
    },
    disconnectMqtt() {
      if (this.mqttClient) {
        this.mqttClient.end();
      }
    },

    isShowtk() {
      // 预警推送弹框
      this.isShows = !this.isShows;
    },
    moveElement() {
      // 元素移动函数
      interact(this.$refs.move.$el).on("dragmove", function (event) {
        x += event.dx;
        y += event.dy;
        event.target.style.transform = "translate(" + x + "px, " + y + "px)";
      });
    },
    mouseDown() {
      //鼠标进入头部开启拖动事件
      interact(this.$refs.move.$el).draggable({
        listeners: {
          move(event) {
            const target = event.target;
            const x =
              (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
            const y =
              (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

            target.style.transform = "translate(" + x + "px, " + y + "px)";

            target.setAttribute("data-x", x);
            target.setAttribute("data-y", y);
          },
        },
        modifiers: [
          interact.modifiers.snap({
            targets: [
              interact.snappers.grid({ x: 2, y: 2 }), // 设置每次移动的像素
            ],
            range: Infinity,
            relativePoints: [{ x: 0, y: 0 }],
          }),
          interact.modifiers.restrict({
            // 设置只能在父元素上移动
            restriction: this.$refs.move.$el.parentNode.parentNode,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
            endOnly: true,
          }),
        ],
        inertia: true,
      });
    },
    dragmove() {
      //鼠标离开头部元素取消拖动事件

      this.whachChange = this.whachChange + 1;
      interact(this.$refs.move.$el).draggable(false);
    },
    closeMountDetails() {
      // 关闭挂载详情
      this.selected_mount = null;
      //关闭选中状态
      this.$refs.menuClose.closemenu3();
    },
    // 记录日志
    async uav_operationTextFn(operationText) {
      await API.UAV.uavOperationLog({
        operationText,
      });
    },
    async control_ws(cate,data) {
      let operationTextType = "无人机应用-控制面板";
      let { user_info_hn } = this;
      let deviceHardId = this.uav_control.deviceHardId;

      if (!deviceHardId) {
        return false;
      }

      let username = user_info_hn.username;

      if (cate == 9) {
        console.log("解锁无人机");
        // 解锁无人机
        this.uav_send_ws({
          type: 200,
          systemCode: "mmc",
          state: 1,
          username,
          data: {
            cmdFunction: 2110,
          },
          deviceHardId,
        });
      } else if (cate == 10) {
        console.log("一键起飞");
        // 一键起飞
        this.uav_send_ws({
          type: 200,
          systemCode: "mmc",
          state: 1,
          username,
          data: {
            cmdFunction: 2111,
          },
          deviceHardId,
        });
      } else if (cate == 11) {
        console.log("安全降落");
        // 安全降落
        if (stationType == 0) {
          this.uav_send_ws({
            type: 200,
            systemCode: "mmc",
            state: 1,
            username,
            data: {
              cmdFunction: 2116,
            },
            deviceHardId,
          });
        } else {
          this.$store.dispatch("mqtt/land", {
            callback: (isOk) => {
              isOk && this.$message.success("发出安全降落指令");
            },
          });
        }
      } else if (cate == 12) {
        if (stationType == 0) {
          // 一键返航
          this.uav_send_ws({
            type: 200,
            systemCode: "mmc",
            state: 1,
            username,
            data: {
              cmdFunction: 2112,
            },
            deviceHardId,
          });
        } else {
          this.$store.dispatch("mqtt/returnFlight", {
            callback: (isOk) => {
              isOk && this.$message.success("发出一键返航");
            },
          });
        }
      }  else if (cate == 111) {
        if (stationType == 0) {
          // 一键返航
          this.uav_send_ws({
            type: 200,
            systemCode: "mmc",
            state: 1,
            username,
            data: {
              cmdFunction: 2112,
            },
            deviceHardId,
          });
        } else {
        
        }
      }else if (cate == 18) {
        if (stationType == 0) {
          console.log("航线模式");
          this.$store.dispatch("mqtt/continueFly", {
            callback: (isOk) => {
              isOk && this.$message.success("发出航线模式");
            },
          });
        } else {
          // 航线模式
          this.uav_send_ws({
            type: 200,
            systemCode: "mmc",
            state: 1,
            username,
            data: {
              cmdFunction: 2115,
            },
            deviceHardId,
          });
        }
      } else if (cate == 15) {
        console.log("航线下发");
        this.$confirm("请确认是否一键进行航线下发操作?", "航线下发确认", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          customClass: "uav_controlPane",
          showClose: false,
        })
          .then(() => {
            this.uav_send_ws({
              type: 200,
              systemCode: "mmc",
              state: 1,
              username,
              data: {
                cmdFunction: 2113,
                cmdValue: controlPanelData.flight_line_id, //航线id
              },
              deviceHardId,
            });
            // 日志
            let operationText =
              operationTextType +
              "-执行指航线下发操作," +
              `设备id: ${deviceHardId}+,航线id:${controlPanelData.flight_line_id}`;
            this.uav_operationTextFn(operationText);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else if (cate == 17) {
        if (stationType == 0) {
          // 暂停飞行
          this.uav_send_ws({
            type: 200,
            systemCode: "mmc",
            state: 1,
            username,
            data: {
              cmdFunction: 2117,
            },
            deviceHardId,
          });
        } else {
          this.$store.dispatch("mqtt/pauseFly", {
            callback: (isOk) => {
              isOk && this.$message.success("暂停飞行");
            },
          });
        }

        let operationText =
          operationTextType + "-执行指暂停飞行操作," + `设备id:${deviceHardId}`;
        this.uav_operationTextFn(operationText);
      } else if (cate == 80) {
        this.handle_location(this.uav_control, 1);
      } else if (cate == 81) {
        if (stationType == 0) {
          // 继续飞行
          this.uav_send_ws({
            type: 200,
            systemCode: "mmc",
            state: 1,
            username,
            data: {
              cmdFunction: 2115,
            },
            deviceHardId,
          });
        } else {
          this.$store.dispatch("mqtt/continueFly", {
            callback: (isOk) => {
              isOk && this.$message.success("继续飞行");
            },
          });
        }
      } else if (cate == 5) {
        console.log("手动模式");
        let reader = new FileReader();
        reader.onload = (event) => {
          let data = event.currentTarget.result;
          data = JSON.parse(data).data;
          this.uav_send_ws({
            type: 200,
            cmdControlType: 111,
            data,
          });
        };
        reader.readAsText(e.data);
      } else if (cate == 99) {
        await this.$confirm("请确认是否进行结束任务操作", "安全确认", {
          center: true,
        });
        let deviceTask = localStorage.getItem("deviceTask");
        if (deviceTask) {
          let task = JSON.parse(deviceTask);
          let res = await API.UAV.taskUpdate({
            id: task.id,
            status: 4,
            deviceHardId: task.deviceHardId,
          });
          if (res.status == 1) {
            localStorage.setItem("deviceTask", null);
            this.$message.success("任务已结束!");
            // 刷新任务列表清除航线
            this.$store.commit("mqtt/setState", {
              key: "refreshTask",
              value: new Date().getTime(),
            });
          }
        } else {
          this.$message.warning("暂无执行中任务！！");
        }
      } else if (cate == 100) {
        this.loggerFlag = true;
      }
    },
    boxWidth(e) {
      this.uav_width = e;
    },
    // 发送指令
    uav_send_ws(data) {
      ws && ws.send(JSON.stringify(data));
    },
    // 获取子组件挂载指令
    handle_mount_directive(data) {
      let { stationType } = this.uav_control;
      // 0为地面站链路 1为飞控中心链路
      if (stationType == 0) {
        if (data && this.selected_mount) {
          // 这个指令发给后台就行了
          let list = {
            type: 200,
            systemCode: "mmc",
            state: 1,
            username: this.user_info_hn.username,
            height: 0,
            idlingTime: 0,
            data: {
              cmdControlType: 7000,
              cmdBuffer: data.toString(),
              cmdName: this.selected_mount.name,
              cmdFunction: null,
              cmdState: null,
              cmdValue: null,
            },
            deviceHardId: this.uav_control.deviceHardId,
          };
          ws && ws.send(JSON.stringify(list));
        }
      } else if (stationType == 1) {
        let mountList = this.$store.state.mqtt.mountList;
        this.$store.dispatch("mqtt/publishMessage", {
          topic: "PX4/OBTAIN/" + this.uav_control.deviceHardId,
          data: {
            type: 514,
            data: {
              mountId: mountList.mountId,
              payload: data,
            },
          },
          callback() {
            data.callback && data.callback(true);
          },
        });
        // }
      }
    },
    // 点击更换挂载信息
    handle_change_mount(mount) {
      this.selected_mount = mount;
    },
    // 初始化ws
    init_ws() {
      let { user_info_hn, uav_control } = this;
      let username = user_info_hn.username;
      console.log("ws0-------------------------------");
      let token =
        user_info_hn["mmc-identity"] + user_info_hn["FLYINGSESSIONID"];
      let appId = user_info_hn.appid;
      ws = new WebSocket(WS_URL);

      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId,
          })
        );
      };

      ws.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (data.deviceData && data.deviceHardId) {
          let index = this.yc_deviceSerial.indexOf(data.deviceHardId);
          const device_data = JSON.parse(data.deviceData);
          if (device_data) {
            if (device_data.deviceHardId === this.uav_control.deviceHardId) {
              const uav_data = device_data.data;
              if (uav_data.videoInfo) {
                this.uav_video = [...uav_data.videoInfo];
              }
              this.uav_data[data.deviceHardId];
              if (!this.uav_data[data.deviceHardId]) {
                this.uav_data[data.deviceHardId] = {};
              }
              this.uav_data[data.deviceHardId] = device_data.data;
              this.$forceUpdate();
            }

            // 无人机数据
            if (this.uavMap[device_data.deviceHardId] && device_data.data) {
              if (device_data.data.uavInfo) {
                const data = this.UAVDataParser(
                  {
                    uavDeviceID: device_data.deviceHardId,
                    uavDeviceName: device_data.deviceHardId,
                    uavInfo: device_data.data.uavInfo,
                  },
                  index !== -1
                );
                if (!this.uavMap[data.hId]) {
                  this.uavMap[data.hId] = {};
                  this.uavMap[data.hId].positions = [];
                }
                this.uavMap[data.hId].position = data.position;
                this.uavMap[data.hId].orientation = data.orientation;
                this.uavMap[data.hId].positions.push(data.position.clone());
              }
            }
          }
        }
      };
      ws.onclose = () => {};
    },

    // 创建无人机模型
    createUAVModel(data) {
      const id = data.hId;
      let entity = viewer.entities.getById(id);
      if (!entity) {
        let model = {
          uri: "./Model/uav_v2.glb",
          minimumPixelSize: 150,
          maximumScale: 20000,
          scale: 1,
        };
        if (data.goodsName == "小旋风") {
          model = {
            uri: "./Model/uavmodel_90deg.glb",
            minimumPixelSize: 128,
            maximumScale: 20000,
          };
        }
        let _this = this;
        entity = viewer.entities.add({
          id: id,
          orientation: new Cesium.CallbackProperty(
            () => this.uavMap[id].orientation,
            false
          ),
          position: new Cesium.CallbackProperty(
            () => this.uavMap[id].position,
            false
          ),
          model: model,
          label: {
            text: data.name || "",
            font: "14pt monospace",
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            outlineWidth: 2,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, -20),
            eyeOffset: new Cesium.Cartesian3(0, 0, -10),
          },
          polyline: {
            positions: new Cesium.CallbackProperty(function () {
              return _this.uavMap[id].positions;
            }, false),
            material: Cesium.Color.GREEN.withAlpha(0.9),
            width: 5,
          },
        });
      }
      return entity;
    },

    UAVDataParser(data, flag) {
      const { uavDeviceID, uavDeviceName, uavInfo } = data;
      const position = Cesium.Cartesian3.fromDegrees(
        Number(uavInfo.longitude),
        Number(uavInfo.latitude),
        Number(uavInfo.height)
      );
      const hpr = new Cesium.HeadingPitchRoll(
        Cesium.Math.toRadians(uavInfo.yaw + flag ? -170 : 90),
        Cesium.Math.toRadians(uavInfo.pitch),
        Cesium.Math.toRadians(uavInfo.roll)
      );
      const orientation = Cesium.Transforms.headingPitchRollQuaternion(
        position,
        hpr
      );
      return {
        hId: uavDeviceID,
        name: uavDeviceName,
        position,
        orientation,
      };
    },

    boxShadowShow(id) {
      this.boxShadowStatus = false;
      this.boxShadow = this.boxShadow.filter((v) => {
        if (v.id == id && v.shadow == true) this.boxShadowStatus = true; //标记是否点击同一个视频拥有高亮
        return v.id != id;
      });
      this.boxShadow.forEach((v, i) => (v.index = i)); //数组去除了重复点击的视频，需要重新给数组排序
      if (this.boxShadowStatus && this.boxShadow.length > 0)
        this.boxShadow[0].shadow = true; //当重复点击的视频拥有高亮时，就要将高亮转移给数组内第一个视频数据
    },
    // 列表实时视频点击方法
    close_video(id) {
      // 关闭某个视频后清掉数组中该视频的记录，可以让该视频可以被再次点击
      this.boxShadowShow(id);
      // 改变列表状态
      this.$refs.one.changeStatus(id, "_video", false);
      this.float_videos = this.float_videos.filter((item) => {
        return item.id != id;
      });
    },
    async handle_video(row) {
      let uav_data = await API.UAV.GetLastUavData({
        deviceHardId: row.deviceHardId,
      });
      if (uav_data.data) {
        row._video = !row._video;
        if (this.float_videos.filter((item) => item.id === row.id).length > 0) {
          this.boxShadowShow(row.id);
          this.close_video(row.id);
          // this.$message('已开启视频')
        } else {
          if (this.float_videos.length < 6) {
            this.float_videos.push({
              ai: row.ai ? row.ai : "",
              id: row.id,
              deviceHardId: row.deviceHardId,
              deviceHardId: row.deviceHardId,
              title: row.deviceName,
              dataSource: uav_data.data.videoInfo,
            });
            switch (this.float_videos.length) {
              case 1:
                this.boxShadow.push({ shadow: true, index: 0, id: row.id });
                break;
              case 2:
                this.boxShadow.push({ shadow: false, index: 1, id: row.id });
                break;
              case 3:
                this.boxShadow.push({ shadow: false, index: 2, id: row.id });
                break;
              case 4:
                this.boxShadow.push({ shadow: false, index: 3, id: row.id });
                break;
              case 5:
                this.boxShadow.push({ shadow: false, index: 3, id: row.id });
                break;
              case 6:
                this.boxShadow.push({ shadow: false, index: 3, id: row.id });
                break;
              default:
                break;
            }
          } else {
            console.log(this.boxShadow.findIndex((v) => v.shadow == true));
            let selectedBox = this.boxShadow.findIndex((v) => v.shadow == true); //获取到点击高亮的视频
            //改变数组内的数据不更新，使用 $set(this.items,index,newitem)
            this.$set(this.float_videos, selectedBox, {
              //将选中的视频替换成下一次点击的视频
              ai: row.ai ? row.ai : "",
              id: row.id,
              deviceHardId: row.deviceHardId,
              deviceHardId: row.deviceHardId,
              title: row.deviceName,
              dataSource: uav_data.data.videoInfo,
            });
            this.boxShadow[selectedBox].id = row.id;
            console.log(this.float_videos);
          }
        }
      } else {
        this.$message.error("暂无实时数据");
      }
    },
    isSelectedShdow(index) {
      console.log(index);
      this.boxShadow.forEach((v) => (v.shadow = false));
      this.boxShadow[index].shadow = true;
    },
    async handle_report(row) {
      const res = await API.TASK.GetReport({
        id: row.id,
      });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(res);
      a.download = `${row.taskTitle}.docx`;
      a.click();
    },
    // 获取当前在线设备
    upOnList(value) {
      this.onLineList = value;
      console.log(this.onLineList, "this.onLineList ");
    },
    // 缩放列表更改视频框样式
    chageVdieoPost() {
      this.videoPostion = !this.videoPostion;
    },
    lonClick(data) {
      this.$refs["cesiumLayer"].addWarning(data);
    },
  },
  mounted() {
    this.getYcId();
    if (this.$route.query.id) {
      this.$refs.one.filter(this.$route.query.id);
    } else {
      this.$refs.one.filter();
    }
  },
  created() {
    this.toggle_mode("1");
    this.init_ws();
    this.connectMqtt();
  },
  beforeDestroy() {
    this.toggle_mode(null);
    if (ws) {
      ws.onclose();
    }
    console.log(entityList, "entityList");
    if (entityList.length > 0) {
      for (let i = 0; i < entityList.length; i++) {
        console.log(11111);
        viewer.entities.remove(entityList[i]);
      }
    }
    this.uavTaskListFlag = false;
  },
  inject: ["toggle_mode"],
  provide() {
    return {
      _uavThis: this,
    };
  },
};
</script>

<style lang="scss" scoped>
.page-platform-visual {
  height: 100%;
}

.cesium-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  // margin: 15px 8px;
  background: center center url(~@/assets/platform/bg-border.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  .cesium-wrap {
    flex: 1;
    position: relative;
  }
}

::v-deep .floating-menu {
  position: fixed;
  top: 60% !important;
  margin-top: -30px !important;
  right: 44px;
  width: 50px;
  height: 260px;
  text-align: center;
  z-index: 1;
}

::v-deep .big-box {
  right: 100px;
}

.movediv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  height: 46px;
  background-color: rgba(0, 56, 67, 0);

  .close {
    position: absolute;
    top: 12px;
    right: 18px;
    font-size: 20px;
    color: #4dabff;
    cursor: pointer;
  }
}

.yjbutton {
  // width: 50px;
  // height:50px;
  position: absolute;
  top: 40px;
  right: 374px;
  z-index: 999;
  // border:1px solid red;
}

.iconfont {
  cursor: pointer;
  font-size: 32px;
  color: #009aff;
  margin-right: 4px;

  &:last-child {
    margin-right: 0px;
  }
}

.yjt-box {
  width: 100%;
  height: 500px;

  .yjt-top {
    display: flex;
    height: 50%;
    margin-bottom: 5px;

    .yjt-top-left {
      flex: 1;

      .img {
        width: calc(100% - 5px);
      }
    }

    .yjt-top-right {
      flex: 1;
      display: flex;
      flex-flow: row-reverse;

      .img {
        width: calc(100% - 5px);
      }
    }
  }

  .yjt-bottom {
    display: flex;
    height: 50%;

    .yjt-bottom-left {
      flex: 1;

      .img {
        width: calc(100% - 5px);
      }
    }

    .yjt-bottom-right {
      flex: 1;

      // display: flex;
      // flex-flow: row-reverse;
      .yjt-txt {
        padding-left: 5px;
        width: calc(100% - 5px);
        color: #fff;
        font-size: 16px;
        margin-bottom: 25px;
      }

      div:nth-child(1) {
        margin: 14px 0 25px;
      }

      div:last-child {
        margin-bottom: 0;
      }
    }
  }
}

::v-deep .el-dialog__body {
  height: 50%;
  box-sizing: border-box;
}

.FiexdRight {
  .floating-menu {
    top: 75%;
  }
}

::v-deep .taskDialong {
  z-index: 0 !important;
}
</style>
