<template>
  <div class="cpt-uav-warning-search">
    <el-form :model="search_form" class="linear-form" >
      <div class="seach">
        <el-input
          placeholder="请输入无人机名称"
          v-model="search_form.search"
          class="input-with-name"
          clearable
          style="height:36px;"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            style="padding: 0;"
            @click="$emit('on-search', search_form)"
          ></el-button>
        </el-input>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search_form: {
        search: null,
      },
    };
  },
};
</script>

<style lang="scss" scoped>

.cpt-uav-warning-search {
  display: flex;
  ::v-deep {
    .linear-form {
      display: flex;
      width: 100%;
      .el-form-item__content {
        .el-input {
          .el-input__inner {
            width: 100%;
          }
        }
      }
    }
  }
  .seach {
    width: 100%;
    .input-with-name {
      height: 36px;
    }
  }
}
</style>
<style lang="scss" >
.cpt-uav-warning-search {
  .seach .input-with-name .el-input__inner,
  .seach .input-with-name .el-input-group__append {
    box-sizing: border-box;
    background: rgba(12, 34, 67, 0.5);
    border: 1px solid #009aff;
    border-radius: 0;
    font-size: 16px;
    color: #9fc4ff;
    letter-spacing: 0;
    font-weight: 400;
    height: 36px;
  }
  .seach .input-with-name .el-input__inner {
    border-right: none;
  }
  .seach .input-with-name .el-input-group__append {
    border-left: none;
  }
}
</style>