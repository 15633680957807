<template>
  <div class="page">
    <div class="division"></div>
    <!-- 左边图片 -->
    <div class="img">
      <div style="width: 50px; margin: 60px 0 0 85px; color: #ffffff">
        {{
          data && data.uavInfo && data.uavInfo.roll
          ? data.uavInfo.roll.toFixed(2)
          : 0
        }}
      </div>
      <div style="width: 50px; margin: 30px 0 0 85px; color: #ffffff">
        {{
          data && data.uavInfo && data.uavInfo.pitch
          ? data.uavInfo.pitch.toFixed(2)
          : 0
        }}
      </div>
    </div>
    <!-- 右边数据 -->
    <div class="data">
      <div class="item">
        <div class="value">
          {{
            data && data.uavInfo && data.uavInfo.height && data.uavInfo.height > 0
            ? data.uavInfo.height.toFixed(2)
            : 0
          }}<span style="color: #fff">m</span>
        </div>
        <div class="dec">飞行高度</div>
      </div>
      <div class="item">
        <div class="value">
          {{
            data && data.uavInfo && data.uavInfo.flightDistance
            ? data.uavInfo.flightDistance.toFixed(2)
            : 0
          }}<span style="color: #fff">m</span>
        </div>
        <div class="dec">飞行里程</div>
      </div>
      <div class="item">
        <div class="value">
          {{
            data && data.uavInfo && data.uavInfo.flightTime
            ? formatSeconds(Number(data.uavInfo.flightTime))
            : 0
          }}
        </div>
        <!-- <div class="value">
          {{
            data && data.uavInfo && data.uavInfo.flightTime
              ? getTime(Number(data.uavInfo.flightTime))[0]
              : 0
          }}
          <span style="color: #fff">min</span>
           {{
            data && data.uavInfo && data.uavInfo.flightTime
              ? getTime(Number(data.uavInfo.flightTime))[1]
              : 0
          }}
          <span style="color: #fff">s</span>
        </div> -->
        <div class="dec">飞行时间</div>
      </div>
      <div class="item">
        <div class="value">
          {{
            data && data.uavInfo && data.uavInfo.groundSpeed
            ? data.uavInfo.groundSpeed.toFixed(2)
            : 0
          }}<span style="color: #fff">m/s</span>
        </div>
        <div class="dec">飞行速度</div>
      </div>
      <div class="item">
        <div class="value">
          {{ (data && data.uavInfo && data.uavInfo.climbRate > 0 && data.uavInfo.climbRate.toFixed(2)) || 0
          }}<span style="color: #fff">m/s</span>
        </div>
        <div class="dec">爬升率</div>
      </div>
      <!-- 电池 -->
      <div class="elec">
        <!-- 左边电池图片 -->
        <!-- <div class="size-wrap">
          <div class="size-box">
            <div class="size-inner">
              <div
                class="size-inner-box"
                :style="`height: ${data && data.uavInfo && data.uavInfo.battaryRemain || 0}%; background-color: ${
                  battery.state.color
                };`"
              ></div>
            </div>
          </div>
        </div> -->
        <!-- 右边电池数据 -->
        <div class="elec-data" v-if="goodsName != '小旋风'">
          <div class="elec-data-title">当前电压</div>
          <div class="elec-data-value">
            {{ battery.voltage || 0 }}
          </div>
          <div class="elec-data-danwei">
            V
          </div>
          <div class="elec-data-state">
            {{ battery.state.label }}
          </div>
        </div>
        <div class="elec-data" v-else>
          <div class="elec-data-title">当前电压</div>
          <div class="elec-data-value">
            {{ batteryObj.voltage || 0 }}
          </div>
          <div class="elec-data-danwei">
            V
          </div>
          <div class="elec-data-state">
            {{ batteryObj.state.label }}
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    batteryData: {
      type: Array|Object ,
      default: () => ([]),
    },
    goodsName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      battery: {
        state: { label: "低电量", color: "#f73939" },
        size: 35,
      },
      batteryObj: {
        state: { label: "低电量", color: "#f73939" },
        size: 35
      },
      state: {
        1: { label: "满电", color: "#76c230" },
        2: { label: "良好", color: "#bd6e01" },
        3: { label: "低电量", color: "#f73939" },
      },
    };
  },
  methods: {
    getTime(time) {
      let m = parseInt(time / 60 % 60)
      let s = parseInt(time % 60)
      s = s < 10 ? '0' + s : s
      // 作为返回值返回
      return [m, s]
    },
    formatSeconds(value) {
      let result = parseInt(value)
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
      let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
      let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))
      result = `${h}:${m}:${s}`
      return result
    }
  },



  watch: {
    data: {
      // 低电量，良好，
      handler(value) {
        
        if (value && value.uavInfo) {
          let { uavInfo } = value;
          if (uavInfo) {
            let size = Math.ceil(uavInfo.voltage);
            let state;
            // 低电量<48  >48正常 >50满电

            if (size < 48) {
              state = this.state["3"];
            } else if (size <= 50) {
              state = this.state["2"];
            } else if (size > 50) {
              state = this.state["1"];
            }

            let voltage = size
            this.battery = {
              size,
              state,
              voltage, //: voltage <= 42 ? 42 : voltage,
            };
          }
        }
      },
      deep: true,
      immediate: true,
    },
    batteryData: {
      handler(value) {
        if (value) {

          let size = Math.ceil(value.voltage);
          let state;
          // 低电量<48  >48正常 >50满电

          if (size < 48) {
            state = this.state["3"];
          } else if (size <= 50) {
            state = this.state["2"];
          } else if (size > 50) {
            state = this.state["1"];
          }

          let voltage = size
          this.batteryObj = {
            size,
            state,
            voltage, //: voltage <= 42 ? 42 : voltage,
          };
        }
      }
    }
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 1005px;
  height: 186px;
  position: absolute;
  bottom: 36px;
  left: 50%;
  margin-left: -522px;
  z-index: 1;

  .division {
    position: absolute;
    top: 104px;
    right: 90px;
    width: 2px;
    height: 80px;
    background-image: linear-gradient(#fff 0%, #70daf9 50%, #fff 100%);
  }

  .img {
    position: absolute;
    width: 1005px;
    height: 186px;
    background: url("./img/circular1.png") center center no-repeat;
    z-index: 1;
    left: 0;
    top: 35px;
  }

  .data {
    // background: rgba(9, 32, 87, 0.7) !important;
    // background: url("~@/assets/dashboard/shape.png") !important;
    height: 90px;
    width: 859px;
    padding-left: 40px;
    box-sizing: border-box;
    position: absolute;
    top: 100px;
    right: 0px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      width: 120px;
      height: 60px;

      .value {
        font-size: 22px;
        font-family: UniDreamLED;
        color: #14d1ff;
      }

      .dec {
        font-size: 16px;
        font-weight: bold;
        color: #e0f3ff;
      }
    }

    .elec {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      width: 68px;

      .size-wrap {
        height: 100%;

        .size-box {
          box-sizing: border-box;
          width: 28px;
          height: 80px;

          .size-inner {
            margin-top: 8px;
            width: 100%;
            height: calc(100% - 8px);
            border: 1px solid #50abff;
            box-sizing: border-box;
            border-radius: 4px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 2px;

            &::before {
              content: " ";
              display: block;
              position: absolute;
              top: -5px;
              left: 50%;
              width: 10px;
              height: 4px;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              border: 1px solid #50abff;
              border-bottom: 0px;
              transform: translateX(-50%);
            }

            .size-inner-box {
              width: 100%;
              border-radius: 4px;
            }
          }
        }
      }

      .elec-data {
        //background: url("./img/circular.png") center center no-repeat;
        z-index: 200;
        left: 0;
        top: 0;
        // margin-left: 12px;
        text-align: center;
        width: 40px;

        &-title {
          color: #fff;
          font-size: 10px;
          margin-bottom: 2px;
          transform: scale(0.83333);
          white-space: nowrap;
        }

        &-value {
          color: #ffbd36;
          font-weight: 600;
          margin-bottom: 2px;
          font-size: 16px;
        }

        &-danwei {
          color: #fff;
          font-size: 10px;
          margin-bottom: 2px;
          transform: scale(0.83333);
          white-space: nowrap;
        }

        &-state {
          width: 46px;
          height: 16px;
          background: #ffbd36;
          border-radius: 1px;
          color: #fff;
          text-align: center;
          line-height: 16px;
          border: 1px solid #ffbd36;
          margin-bottom: 2px;
          transform: scale(0.83333);
          white-space: nowrap;
          font-size: 10;
        }
      }
    }
  }
}
</style>
