<template >
    <div class="w400 h250">
        <video :id="id" class="video-js wih100 ht100 vjs-default-skin" preload="auto">
            <source :src="video.vUrl" type="application/x-mpegURL">
        </video>
    </div>
</template>
<script>
import videojs from 'video.js'
import 'videojs-contrib-hls'
import "@videojs/http-streaming"
export default {
    data() {
        return {
            video: {
                vUrl: ''
            },
            player: '',
            id: "",
            options: {
                autoplay: true, //自动播放
                controls: true, //用户可以与之交互的控件
                loop: false, //视频一结束就重新开始
                muted: true, //默认情况下将使所有音频静音
                playsinline: true,
                webkitPlaysinline: true,
                playbackRates: [0.5, 1, 1.5, 2],
                language: "zh-CN",
                fullscreen: {
                    options: { navigationUI: "hide" },
                },
            },
        }
    },
    props: {
        dataSource: {
            type: Array,
            default: () => [],
        },
    },
    created() {
        this.id = this.generateRdStr(10)
    },
    methods: {
        generateRdStr(len) {
            len = len || 32;
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < len; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            return text;
        },

        init() {
            let _this = this
            // 播放器初始化
            this.$nextTick(() => {
                let dom = document.getElementById(this.id)
                this.player = videojs(dom, this.options)
                this.player.src([
                    {
                        src: this.video.vUrl,
                        type: "application/x-mpegURL", // 告诉videojs,这是一个hls流
                    },
                ]);
                this.player.play()

            })
        },
    },
    beforeDestroy() {
        //  组件销毁时，清除播放器
        if (this.player) {
            this.player.dispose(); // 该方法会重置videojs的内部状态并移除dom
        }
    },
    watch: {
        dataSource: {
            handler(value) {
                if (value && value.length) {
                    let video = value.find((item) => item.videoType.indexOf("hls") !== -1);
                    let ip = window.location.host;
                    if (ip == '172.16.6.20') {
                        this.video = {
                            vUrl: video.vUrl.replace(/201.10.1.222:10935/, '172.16.6.16:10800'),
                            videoType: video.videoType
                        }
                    } else {
                        this.video = video
                    }
                    this.$nextTick(() => {
                        this.init();
                    });
                }
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>
<style lang="">
    
</style>