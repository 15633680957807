<template>
  <Dialog
    title="预警消息"
    :titleCanMove="true"
    :isShowClose="true"
    @onClose="close"
    :containerStyle="{
      width: '340px',
      top: '40px',
      right: '100px',
      zIndex: 2,
      position: 'absolute',
    }"
  >
    <!-- <Search @on-search="handle_search" /> -->
    <List :dataSource="dataSource" />
  </Dialog>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";

import Dialog from "@/components/common/dialog";
import Search from "./search";
import List from "./list";
import mqtt from "mqtt";
import dayjs from "dayjs";
const { VUE_APP_MTQQ } = process.env;
export default {
  props: {
    onLineList: {
      type: Array,
      default: [],
    },
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  components: { Dialog, Search, List },
  data() {
    return {
      dataSource: [],
      search: {},
      client: null,
      ws: null,
    };
  },
  methods: {
    init_ws() {
      let { user_info_hn, uav_control } = this;
      let username = user_info_hn.username;
      let token =
        user_info_hn["mmc-identity"] + user_info_hn["FLYINGSESSIONID"];
      let appId = user_info_hn.appid;
      this.ws = new WebSocket(VUE_APP_MTQQ);
      this.ws.onopen = () => {
        console.log("连接ws");
        this.ws.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId,
          })
        );
      };

      this.ws.onmessage = (e) => {
        const data = JSON.parse(e.data);
        console.log(data);
      };
      this.ws.onclose = () => {};
    },
    close() {
      this.$emit("onclose");
    },
    mapLineList(data) {
      let arr = [];
      data.forEach((element) => {
        if (element.devices) {
          element.devices.forEach((fly) => {
            if (fly.reportStatus === 1) {
              arr.push("mmc/warning/" + fly.deviceHardId);
            }
          });
        }
      });
      return arr;
    },
    // 初始化mtqq
    initMtqq() {
      // let list = this.onLineList.map(item => 'mmc/warning/' + '0UYKHBT00201B4')
      // let list = this.mapLineList(this.onLineList)
      let deviceHardId = this.$store.state.device.deviceHardId;
      let list = [`mmc/warning/${deviceHardId}`];
      const options = {
        connectTimeout: 40000,
        clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
        username: "admin",
        password: "mmcaabcE",
        clean: true,
      };
      this.client = mqtt.connect(VUE_APP_MTQQ, options);

      if (this.client.connected) {
        this.client.end();
      }

      // mqtt连接
      this.client.on("connect", (e) => {
        console.log("连接成功:");
        this.client.subscribe(list, { qos: 1 }, (error) => {
          if (!error) {
            console.log("订阅成功", list);
          } else {
            console.log("订阅失败");
          }
        });
      });
      // 接收消息处理
      this.client.on("message", (topic, message) => {
        let data = message.toString();
        console.log("收到来自", topic, "的消息", data);
        let dataList = JSON.parse(
          data.replaceAll("\\", "").replaceAll('"[', "[").replaceAll(']"', "]")
        );
        console.log(dataList, "dataList");
        let params = {
          ...dataList,
          time: dayjs(dataList.addTime).format("YYYY-MM-DD HH:mm:ss"),
          scene: dataList.scene,
        };
        if (this.dataSource.length > 500) {
          this.dataSource.shift();
        }
        this.dataSource.push(params);
      });
      // 断开发起重连
      this.client.on("reconnect", (error) => {
        console.log("正在重连:", error);
      });
      // 链接异常处理
      this.client.on("error", (error) => {
        console.log("连接失败:", error);
      });
    },
  },
  mounted() {
    this.initMtqq();
    this.init_ws();
  },
  destroyed() {
    if (this.client) {
      // this.client.disconnect()
      this.client.end();
      this.ws && this.ws.close();
      this.ws = null;
    }
  },
  watch: {
    onLineList: {
      //监听在线设备，断开mtqq，重新连接订阅
      handler: function (newval, oldval) {
        if (this.client && newval.toString() != oldval.toString()) {
          // this.client.disconnect()
          this.client.end();
          this.initMtqq();
        }
      },
    },
  },
};
</script>

<style></style>
