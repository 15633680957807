var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"big-box",class:_vm.telescopic2 ? 'telescopic2' : 'telescopics2'},[_c('Dialog',{staticClass:"taskDia",attrs:{"isShowClose":false,"isShowTitle":false,"containerStyle":{
      width: '500px',
      zIndex: 10,
    }}},[_c('Search',{on:{"on-search":_vm.handle_search}}),_c('div',{class:_vm.telescopic ? 'telescopic' : 'telescopics'},_vm._l((_vm.list),function(item){return _c('Item',{key:item.id,attrs:{"data":item}})}),1),_c('div',{staticClass:"ssanniu",on:{"click":function($event){return _vm.stretch(1)}}},[(this.telescopic)?_c('div',{staticClass:"up"}):_c('div',{staticClass:"down"})])],1),_c('div',{staticClass:"ssanniu2_box"},[_c('div',{staticClass:"ssanniu2",on:{"click":function($event){return _vm.stretch(2)}}},[(this.telescopic2)?_c('div',{staticClass:"left"}):_c('div',{staticClass:"right"})])]),(_vm.type === 'task')?_c('Dialog',{staticClass:"task",attrs:{"isShowClose":false,"isShowTitle":false,"containerStyle":{
    width: '500px',
    height: '355px',
    top: '10px',
    left: '4px',
    zIndex: 10,
    position: 'absolute'
  }}}):_vm._e(),(_vm.Details_List)?_c('Details',{attrs:{"Details_List":_vm.Details_List},on:{"on-closeDetails":_vm.closeDetails}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }