<template>
    <div class="taskListBox w380" :class="{ 'taskListBoxFlag': telescopic2 }"
        v-interact="{ resize: [false, true, false, false] }">
        <div class="header wih100 h32">
            <div class="title ml10">
                <!-- <img src="~@/assets/images/mount/mount_head.png" /> -->
                <div class="font">任务库</div>
            </div>
        </div>
        <div class="pt38 jianju pr">
            <div class="flexs">
                <span class="cf">任 务 库</span>
                <el-tooltip :content="clew" placement="top">
                    <el-select v-model="warningTypeId" size="mini" clearable placeholder="请选择任务" class="button ml10">
                        <el-option v-for="item in task_list" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                </el-tooltip>
                <div class="cf mt20 tc" style="display: flex">
                    <span class="line_height">
                        航
                        <i style="opacity: 0">任</i> 线
                    </span>
                    <el-tooltip content="航线名称" placement="top">
                        <!-- 如果任务自带航线 就 不能选航线 -->
                        <el-select @change="showLine" :disabled="flightFlag" v-model="lineId" size="mini" clearable
                            placeholder="请选择航线" class="button ml10">
                            <el-option v-for="item in flightLineList" :key="item.id" :label="item.flightName"
                                :value="item.id" />
                        </el-select>
                    </el-tooltip>
                </div>
                <div>
                    <div></div>
                    <div class="btn cf ml18 cp mt40" @click="handClickTask">一键任务</div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
import API from "@/api";
let airline_entitys = []
import { Utils } from "@/lib/cesium";

export default {
    name: "taskList",
    data() {
        return {
            // 任务id
            warningTypeId: null,
            // lineId 航线id
            lineId: null,
            rwList: [],
            TaskList: [],
            // 任务数据
            task_list: [],
            // 航线数据
            flightLineList: [],
            clew: "任务库", // 飞控任务库提示语
            flightSortic: null,
            taskCate: 1,
            distanceLine: 0,
            // 选中任务
            activeTask: null,
            // 选中任务是否有航线
            flightFlag: false
        };
    },
    computed: {
        telescopic2() {
            return this.$store.state.device.telescopic2
        }
    },
    watch: {
        device(value) {
            if (value) {

            }
        },
        'warningTypeId'(val) {
            if (val) {
                this.chanegline(val)
            }

        },
        '$store.state.mqtt.refreshTask'(val) {
            if (val) {
                this.warningTypeId = null
                this.lineId = null
                this.clonse_airline_entitys()
                this.initTask();
            }

        },
        '$store.state.device.deviceHardId'(val) {
            if (val) {
                this.warningTypeId = null
                this.lineId = null
                this.clonse_airline_entitys()
                this.initTask();
            }
        },
        'lineId'(val) {
            if (val) {
                console.log('航线id');
                this.showLine(val)
            }
        }
    },
    props: {
        wsShow: {
            type: Boolean,
            default: false,
        },
        device: {
            type: Object,
            default: () => ({}),
        },
        taskstyle: {
            type: Boolean,
            default: false,
        },
    },
    async created() {
        this.lineList()
        await this.initTask();
        // 获取执行中的航线
        this.getDeviceOfTaskFn()

    },
    async mounted() {

    },
    methods: {
        async getDeviceOfTaskFn() {
            let deviceHardId = this.$store.state.device.deviceHardId
            let res = await API.UAV.getDeviceOfTask(deviceHardId)
            if (res && res.data.length > 0) {
                this.task_list.push(res.data[0])
                this.warningTypeId = res.data[0].id
                localStorage.setItem("deviceTask", JSON.stringify({
                    id: this.warningTypeId,
                    deviceHardId: deviceHardId,
                    lineId: res.data[0].flightId
                }));
            } else {
                localStorage.setItem("deviceTask", null);
            }
        },
        async lineList() {
            let res = await API.AIRWAY.flightLineList({
                pageNo: 1,
                pageSize: 1000,
            })
            this.flightLineList = res.data.data
        },
        async initTask() {
            let res = await API.UAV.selectLikeList({ page: 1, page_size: 1000, type: 2 })
            this.task_list = res.data
        },
        // 起飞
        async takeOff() {
            let deviceHardId = this.$store.state.device.deviceHardId
            let res = this.flightLineList.filter(item => item.id == this.lineId)
            if (res.length > 0) {
                // 改变任务状态
                await API.UAV.taskUpdate({
                    id: this.warningTypeId,
                    status: 3,
                    deviceHardId: deviceHardId
                })
                // 保存任务
                localStorage.setItem("deviceTask", JSON.stringify({
                    id: this.warningTypeId,
                    deviceHardId: deviceHardId,
                    lineId: this.lineId
                }));
                const flightCourseJson = JSON.parse(res[0].flightCourseJson)
                let lineData = []
                flightCourseJson.points.forEach(item => {
                    lineData.push({
                        "altitude": item.alt,
                        "coordinate": {
                            "latitude": item.lat,
                            "longitude": item.lon
                        },
                        "frame": 3,
                        "speed": 10,
                        "stay": 0,
                        "waypointActions": item.actions
                    })
                });

                this.$message.success('一键任务')
                let content = lineData
                let data = {
                    content,
                    taskId: this.warningTypeId
                }
                this.$store.commit('mqtt/setState', {
                    key: 'airlineData',
                    value: data
                })
                this.$store.dispatch("mqtt/takeOff", {
                    callback: (isOk) => {
                        isOk && this.$message.success("发出一键任务指令");
                    },
                });

                // 改变任务状态
            }
        },
        async handClickTask() {
            await this.$confirm("请确认是否进行一键任务操作", "安全确认", {
                center: true
            });
            let deviceHardId = this.$store.state.device.deviceHardId
            //   查询是否有在执行的任务
            let res = await API.UAV.getDeviceOfTask(deviceHardId)
            if (res.data) {
                return this.$message.warning(res.message)
            }
            if (this.lineId && this.warningTypeId) {
                // 打开飞行检查
                this.$emit('selfTest', true)
            } else {
                if (!this.warningTypeId) {
                    this.$message.warning('请选择任务！')
                } else {
                    this.$message.warning('请选择航线！')
                }
            }

        },
        showLine(id) {
            let res = this.flightLineList.filter(item => item.id == id)
            if (res.length > 0) {
                const flightCourseJson = JSON.parse(res[0].flightCourseJson)
                this.handleAirLinePreview(flightCourseJson)
            }
        },
        chanegline(id) {
            let task = this.task_list.filter(res => {
                return res.id == id
            })
            if (task.length > 0) {
                this.activeTask = task[0]
                let flightId = task[0].flightId
                if (flightId) {
                    this.lineId = flightId
                    this.flightFlag = true
                    // this.showLine(flightId)
                } else {
                    this.flightFlag = false
                    this.lineId = null
                }
            }
        },
        create_label(before, after) {
            let viewer = window.viewer;
            let before_wgs84 = Utils.transformCartesian2WGS84(before);
            let after_wgs84 = Utils.transformCartesian2WGS84(after);

            let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
            let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
            let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

            let position = Utils.transformWGS842Cartesian({
                lng: center_lng,
                lat: cetner_lat,
                alt: alt + 10,
            });

            let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

            let label_entity = viewer.entities.add({
                id: `label_${before}`,
                position: position,
                label: {
                    text: text,
                    scale: 0.5,
                    font: "bold 30px Microsoft YaHei",
                    fillColor: Cesium.Color.fromCssColorString("#fff"),
                    horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    showBackground: true,
                    backgroundPadding: new Cesium.Cartesian2(10, 10),
                },
            });
            airline_entitys.push(label_entity);
        },
        async handleAirLinePreview(item, state) {
            try {
                let viewer = window.viewer;
                if (airline_entitys?.length) {
                    airline_entitys.forEach((item) => {
                        viewer.entities.remove(item);
                    });
                }
                //     // 有航点高度和距离的代码
                if (item) {
                    let positions = [];
                    let center = [];
                    let polyline = item
                    let label_arr = [];
                    let _this = this;

                    polyline.points.forEach((item, index) => {
                        positions.push(item.lon, item.lat, item.alt);
                        label_arr.push(
                            Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
                        );
                        let point_entity = viewer.entities.add({
                            position: Cesium.Cartesian3.fromDegrees(
                                item.lon,
                                item.lat,
                                item.alt
                            ),
                            name: "show_airline_point",
                            point: {
                                pixelSize: 10,
                                color: Cesium.Color.fromCssColorString("#00A9A9"),
                            },
                            label: {
                                text: String(item.alt) + "m",
                                scale: 0.6,
                                font: "bold 28px Microsoft YaHei",
                                // fillColor: Cesium.Color.BLUE,
                                fillColor: Cesium.Color.fromCssColorString("#FF7F09"),
                                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                                showBackground: false,
                                outlineWidth: 0,
                                pixelOffset: new Cesium.Cartesian2(25, -10),
                            },
                        });
                        airline_entitys.push(point_entity);
                        if (label_arr.length > 1) {
                            let before = label_arr[label_arr.length - 2];
                            let after = label_arr[label_arr.length - 1];
                            _this.create_label(before, after);
                        }
                    });
                    positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions);
                    let redLine = viewer.entities.add({
                        name: "Red line on terrain",
                        polyline: {
                            positions,
                            width: 4,
                            material: Cesium.Color.fromCssColorString("#00A9A9"),
                        },
                    });

                    viewer.flyTo(redLine);
                    airline_entitys.push(redLine);
                }
            } catch (error) {
                console.log(error, "error");
                this.$el_message("暂无航线", () => { }, "error");
            }
        },
        clonse_airline_entitys() {
            airline_entitys.forEach((item) => {
                window.viewer.entities.remove(item);
            });
        }
    },
    beforeDestroy() {
        this.$store.dispatch("mqtt/disconnect");
        this.clonse_airline_entitys()
    }
};
</script>
<style>
.el-cascader-menu__list {
    background: rgba(9, 32, 87, 1) !important;
    max-height: 300px;
    max-width: 327px;
}

.el-cascader-node {
    background: rgba(9, 32, 87, 1) !important;
}
</style>
<style lang="scss" scoped>
.yidong {
    left: 544px !important;
}

.taskListBox {
    position: fixed;
    left: 50px;
    top: 179px;
    min-height: 254px;
    background: rgba(9, 32, 87, 0.7);
    border-radius: 10px;
    transition: 0.3s;
    z-index: 20;

    .header {
        display: flex;
        justify-content: space-between;
        height: 32px;
        background: linear-gradient(180deg,
                #9198ff 0%,
                rgba(45, 81, 153, 0.45) 40%,
                #05091a 100%);
        box-shadow: inset 0px 0px 10px 2px #3f9dff;
        border-radius: 10px 10px 0px 0px;
        border: 1px solid #427dff;

        .title {
            display: flex;
            align-items: center;

            .font {
                font-size: 20px;
                font-family: YouSheBiaoTiHei;
                color: #14faff;
                line-height: 26px;
                text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
                background: linear-gradient(135deg,
                        #e3aa77 0%,
                        #f5cda9 38%,
                        #f9ecd3 58%,
                        #fcdbb1 79%,
                        #edb07a 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .active {
            background-image: linear-gradient(180deg,
                    #9198ff 0%,
                    rgba(45, 81, 153, 0.22) 40%,
                    #05091a 100%);
            border: 1px solid #70daf9;
            box-shadow: inset 0 0 10px 2px #3f9dff;
            font-family: MicrosoftYaHei-Bold;
            font-size: 14px;
            color: #70daf9;
            letter-spacing: 0;
            font-weight: 700;
        }

        .default {
            background-image: linear-gradient(180deg,
                    #9198ff 0%,
                    rgba(45, 81, 153, 0.22) 40%,
                    #05091a 100%);
            border: 1px solid #70daf9;
            font-family: MicrosoftYaHei-Bold;
            font-size: 14px;
            color: rgba(112, 218, 249, 0.5);
            letter-spacing: 0;
            font-weight: 700;
        }
    }

    .jianju {
        // background: rgba(9, 32, 87, 0.7);
        border-radius: 10px 0 0 10px;
        display: flex;
        justify-content: center;
    }

    .taskList {
        overflow-y: auto;

        .rwtitle {
            font-family: MicrosoftYaHei-Bold;
            font-size: 14px;
            color: #b5e5ff;
            padding: 5px 0;
            letter-spacing: 0;
            font-weight: 700;
            background: rgba(87, 96, 138, 0.2);
            border: 1px solid rgba(207, 234, 255, 0.33);
        }

        //   .rwinfo {
        //     color: #ffffff;
        //     background: url("~@/assets/images/observe/fckernel/nest/listBg.png");
        //     background-repeat: no-repeat;
        //     background-size: 100% 100%;
        //     height: 33px;
        //     line-height: 33px;
        //     padding-left: 4px;
        //   }
    }

    .btn {
        width: 122px;
        height: 37px;
        text-align: center;
        line-height: 37px;
        margin: 0 auto;
        margin-bottom: 10px;
        background: rgba(38, 71, 238, 0.71);
        box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
        border-radius: 6px;
        // border: 1px solid #70daf9;
        // box-shadow: inset 0 0 10px 2px #3f9dff;
        //   background: url("~@/assets/yingkong1027/btn_lan.png") no-repeat;
        background-size: 100% 100%;
    }

    .Titlehideen {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.taskListBoxFlag {
    left: 559px;

}

.el-cascader-menu {
    width: 200px;
}

.mrg_left6 {
    margin-left: 10px;
}

.kbt_button {
    margin-left: 10px;
    width: 80%;
    height: 30px;
    padding: 0;
}


.line_height {
    line-height: 28px;
}
</style>
  