<template>
  <div class="cpt-device-mount-list"></div>
</template>

<script>
export { default as MountData } from "./data.js"

export default {

}
</script>

<style lang="scss" scoped>
.cpt-device-mount-list {

}
</style>