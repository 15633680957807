<template>
  <div class="cpt-cesium-layer-addpoint" @mouseenter="CesiumLayerMouseEnter" @mouseleave="CesiumLayerMouseLeave">
    <div id="layer-container-addpoint"></div>
    <div @click="$emit('clear')">x</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { initMap } from "@/utils/cesium.js";

export default {
  props: {
    point: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters(["my_layers_data"]),
  },
  data() {
    return {
      VUE_APP_IP: null,
      elementList: [],
      tagging: null,
      entity: null,
    };
  },
  methods: {
    mouseScoll(e) {
      //鼠标滚轮滚动效果
      /*这里获取元素节点*/
      let oPanel = document.getElementById("app");
      let Ripples = document.getElementsByClassName(
        "hwb-mouse-scrolling-effect"
      );
      if (Ripples.length > 0) {
        return;
      }
      let oSpan = document.createElement(`div`);
      /*浏览器兼容*/
      e = e || window.Event;
      /*获取相关参数*/
      oSpan.style.left = e.clientX + `px`;
      oSpan.style.top = e.clientY + `px`;
      /*给节点赋值参数数值*/
      oSpan.className = "hwb-mouse-scrolling-effect";
      /*在body中添加span标签*/
      oPanel.appendChild(oSpan);
      /*设置定时器 间隔时间为2000毫秒*/
      setTimeout(() => {
        /*清除ospan节点*/
        oSpan.remove();
      }, 600);
    },
    CesiumLayerMouseEnter() {
      // 鼠标移入地球添加鼠标滚动效果
      document.addEventListener(`mousewheel`, this.mouseScoll);
    },
    CesiumLayerMouseLeave() {
      // 鼠标移出地球添加鼠标滚动效果
      document.removeEventListener(`mousewheel`, this.mouseScoll);
    },
    init_viewer() {
      // 设置相机初始位置为中国上空
      Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
        90,
        -20,
        110,
        90
      );

      let viewer = (window.viewer = new Cesium.Viewer(
        "layer-container-addpoint",
        {
          animation: false, // 动画控制不显示
          shouldAnimate: true,
          timeline: false, // 时间线不显示
          fullscreenButton: false, // 全屏按钮不显示
          creditContainer: document.createElement("div"),
          geocoder: false,
          homeButton: false,
          sceneModePicker: false, // 切换球形和平面型
          baseLayerPicker: false,
          navigationHelpButton: false,
          infoBox: false,
          selectionIndicator: false,

          requestRenderMode: true, //启用请求渲染模式
          scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
          sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode

          imageryProvider: new Cesium.TileMapServiceImageryProvider({
            url: Cesium.buildModuleUrl("Assets/Textures/NaturalEarthII"),
          }),
        }
      ));
      // viewer._container.style.cursor = "crosshair"

      if (this.my_layers_data === "night") {
        viewer.imageryLayers.addImageryProvider(
          new Cesium.UrlTemplateImageryProvider({
            url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
          })
        );
      } else if (this.my_layers_data === "tian") {
        viewer.imageryLayers.addImageryProvider(
          new Cesium.TileMapServiceImageryProvider({
            url: Cesium.buildModuleUrl("Assets/Textures/NaturalEarthII"),
          })
        );

        viewer.imageryLayers.addImageryProvider(
          new Cesium.WebMapTileServiceImageryProvider({
            url:
              "https://{s}.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0" +
              "&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}" +
              "&style=default.jpg&tk=" +
              "0dabff806320955417bec4c93aa77c0b",
            layer: "cia_w",
            style: "default",
            format: "tiles",
            tileMatrixSetID: "GoogleMapsCompatible",
            subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"], //天地图8个服务器
            minimumLevel: 0,
            maximumLevel: 18,
          })
        );
      }

    },
    addpoint() {
      let data = this.point.split(',')
      // 添加点
      viewer.entities.remove(this.entity);
      //添加点位
      this.entity = new Cesium.Entity({
        id: 'flyTmp',
        position: Cesium.Cartesian3.fromDegrees(data[0], data[1]),
        point: {
          image: require("@/assets/icon-location.png"),
          pixelSize: 20,
          color: Cesium.Color.RED.withAlpha(0.9),
          outlineColor: Cesium.Color.RED.withAlpha(0.9),
          outlineWidth: 1,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        }
      });
      viewer.entities.add(this.entity);
    }
  },
  mounted() {
    const { VUE_APP_IP } = process.env;
    this.VUE_APP_IP = VUE_APP_IP;
    this.init_viewer();
    this.addpoint()

  },
  beforeDestroy() {
    this.CesiumLayerMouseLeave()
  },
};
</script>

<style lang="scss" scoped>
.cpt-cesium-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  #layer-container {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>
