<template>
  <Dialog
    title="挂载面板"
    :isShowClose="false"
    :containerStyle="{
      width: '500px',
      height: '182px',
      top: '410px',
      left: '27px',
      zIndex: 11,
    }"
  >
    <MountBooth
      @on-change="(e) => $emit('on-change', e)"
      :dataSource="MountData.list"
    />
  </Dialog>
</template>

<script>
import Dialog from "@/components/common/dialog";
import MountBooth from "@/components/device/mount-booth";
import { MountData } from "@/components/device/mount-list";
export default {
  components: { Dialog, MountBooth },

  data() {
    return {
      MountData,
    };
  },
};
</script>

<style>
</style>