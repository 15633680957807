<template>
  <div class="cpt-uav-warning-list">
    <div class="aaa" style="display: flex;flex-direction: column-reverse;">
    <div class="warning-item" v-for="(item, index) in dataSource" :key="index" id="warningID-item">
      <div id="warningID-item-slot">
      <div class="warning-item-slot">
        <div class="warning-item-slot__title">
          <div>预警类别：{{ item.warningTypeName||item.aiName }}</div>
        </div>
        <div class="warning-item-slot__boxs">
          <SlotBox
            v-if="typeof(item.scene) === 'string'"
            :key="`box_` + index"
            :url="item.scene"
            :boxes="item.boxes"
          />
          <img class="img" v-else-if='item.aiName' :src="item.scene" alt="">
          <img class="img" v-else-if="typeof(item.scene) === 'object'" @click="showPicture(item.scene)" :src="item.scene[0]||item.scene" alt="">
        </div>
        <div class="lon">
          <div>预警时间：{{ item.time }}</div>
          <div @click="logclick(item.lng + ',' + item.lat)">经纬度： {{ item.lng + ',' + item.lat }}</div>
        </div>
      </div>
      </div>
    </div>
    </div>
    
  </div>
</template>

<script>
import SlotBox from "./slot-box.vue";
import AddPoint from "@/components/addPoint"
export default {
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
  },
  components: { SlotBox, AddPoint },
  data() {
    return {
      vis: false,
      point: null
    }
  },
  methods: {
    showPicture(urls) { // 点击展示图片
        let images = []
        urls.forEach(item => {
          images.push(item.trim())
        });
        this.$viewerApi({
          images: images,
        })
    },
    // 点击经纬度
    logclick(data) {
      this.lonClick(data)
    },
  },
  inject: ["lonClick"],
};
</script>

<style lang="scss" scoped>
.cpt-uav-warning-list {
  width: 315px;
  height: 554px;
  // overflow: hidden;
  overflow: auto;
  margin-top: 16px;
  .warningID-item {
    // height: 100%;
    overflow: auto;
  }
  #warningID-item-slot {
    overflow: auto;
    height: 100%;
  }
  .warning-item {
    // height: 100%;
    background: rgba(17, 31, 58, 0.5);
    border: 1px solid rgba(0, 69, 115, 0.5);
    box-sizing: border-box;
    padding: 0 4px;
    .warning-item__title {
      font-size: 14px;
      font-weight: bold;
      color: #bbcff0;
      line-height: 19px;
      text-align: center;
      height: 40px;
      line-height: 40px;
    }
    .warning-item-slot {
      .warning-item-slot__title {
        display: flex;
        justify-content: center;
        height: 36px;
        background: linear-gradient(
          270deg,
          rgba(64, 179, 255, 0) 0%,
          rgba(72, 140, 255, 0.3) 100%
        );
        font-size: 14px;
        color: #40b3ff;
        line-height: 19px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 16px;
        margin-bottom: 5px;
        &::before {
          content: " ";
          display: inline-block;
          height: 100%;
          width: 6px;
          background-color: #488cff;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
        }
      }
      .warning-item-slot__boxs {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .img {
          width: 124px;
          height: 80px;
        }
      }
      .lon {
        color: #bbcff0;
        font-size: 16px;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}
</style>