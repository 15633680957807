<template>
  <div class="flexright-menu">
    <!-- <div class="flexright-menu-box" :class="showBorder? 'showBorder' : null " @click="show=!show;showBorder=!showBorder">
            <div><img :src="image"></div>
            <span>任务</span>
    </div>-->
    <div
      class="flexright-menu-box"
      v-for="item in menuList"
      :key="item.id"
      :class="item.showBorder ? 'showBorder' : null"
      @click="add(item)"
    >
      <div>
        <img :src="item.image" />
      </div>
      <span>{{ item.title }}</span>
    </div>
    <!-- 健康管理 -->
    <Dialog
      title="健康管理"
      titleCanMove
      :isShowClose="true"
      class="action"
      :containerStyle="{
        width: '450px',
        top: '0px',
        right: '54px',
        position: 'absolute',
      }"
      :visible.sync="menuList[0].show"
      v-if="menuList[0].show"
    >
      <div class="operation">
        <Health></Health>
      </div>
    </Dialog>
    <!-- 视图库 -->
    <!-- <Dialog title="视图库" titleCanMove :isShowClose="true" class="action" :containerStyle="{
      top: '52px',
      right: '54px',
      position: 'absolute'
    }" :visible.sync="menuList[1].show" v-if="menuList[1].show">
      <div class="">
        <div class="stk">
          <div class="stk_head">
            <div :class="isfk == 1 ? 'ac' : ''" @click="isflyk(1)">视图</div>
            <div :class="isfk == 2 ? 'bc' : ''" @click="isflyk(2)">机载</div>
          </div>
          <div class="content">
            <div class="head" :style="isfk == 2 ? 'justify-content: space-between;' : 'justify-content: flex-end;'">
              <div class="bt jcsb" v-if="isfk == 2">
                <div class="btns" @click="submit">转存</div>
                <div class="btns ml12" @click="handleCheckAllChange">全选</div>
              </div>
              <div class="select" v-if="isfk == 2">
                <div class="btns" @click="changephotoType(0)">图片</div>
                <div class="btns" @click="changephotoType(1)">视频</div>
                <span class="iconfont icon-gengxin" @click="reset"></span>
              </div>
              <div class="select" v-if="isfk == 1">
                <div class="btns" :class="filetypev2 == 1 ? 'bg' : ''" @click="changephotoType(1)">图片</div>
                <div class="btns" :class="filetypev2 == 2 ? 'bg' : ''" @click="changephotoType(2)">视频</div>
                <span class="iconfont icon-gengxin" @click="reset"></span>
              </div>
            </div>
            <div class="lists">
              <div class="boxs" v-if="isfk == 1">
                <div class="task" v-for="(item, index) in photoList" :key="index">
                  <div class="name">{{ item.taskName }}</div>
                  <div class="images">
                    <div class="box" v-for="(item2, index2) in photoList[index].list" :key="index2"
                      :class="isfk == 1 && filetypev2 == 2 ? 'boxv2' : ''">
                      <img @click="lookBig(item2)" :src="imgApi + '/uploads' + item2.fileKey" />
                      <video controls v-if="isfk == 1 && filetypev2 == 2"
                        :src="imgApi + '/uploads' + item2.fileKey"></video>
                      <div class="down" v-if="isfk == 1 && filetypev2 == 1" @click="download(item2, index2)">下载</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="boxs jz" v-else>
                <el-checkbox-group v-model="checkList">
                  <div class="box" v-for="(item, index) in photoList" :key="index"
                    :class="isfk == 2 && filetype == 1 ? 'boxv2' : ''">
                    <el-checkbox :label="item">
                      <el-image v-if="isfk == 2 && filetype == 0" :src="item.jsonFilePath" fit="cover"
                        :preview-src-list="[item.jsonFilePath]"></el-image>

                      <video controls v-if="isfk == 2 && filetype == 1" :src="item.jsonFilePath"></video>
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>-->
    <!-- 警灯控制 -->
    <Dialog
      title="警灯控制"
      titleCanMove
      :isShowClose="true"
      class="action"
      :containerStyle="{
        width: '550px',
        top: 105 + 'px',
        right: '54px',
        position: 'absolute',
      }"
      :visible.sync="menuList[1].show"
      v-if="menuList[1].show"
    >
      <div class="h300">
        <div class="police">
          <div class="police_ceonter">
            <!-- 警灯模式 -->
            <div class="police_controls">
              <div class="plice_fontTwo">警灯模式:</div>
              <div>
                <el-radio v-model="taillights" label="1" @change="police_kong(1, 1)">关闭</el-radio>
                <el-radio v-model="taillights" label="2" @change="police_kong(1, 2)">慢闪</el-radio>
                <el-radio v-model="taillights" label="3" @change="police_kong(1, 3)">快闪</el-radio>
                <el-radio v-model="taillights" label="4" @change="police_kong(1, 4)">交替闪</el-radio>
              </div>
            </div>
            <!-- 尾灯 -->
            <div class="police_controls">
              <div class="plice_fontTwo">尾灯:</div>
              <div>
                <el-radio v-model="weideng" label="1" @change="police_wei(2, 1)">开</el-radio>
                <el-radio v-model="weideng" label="2" @change="police_wei(2, 2)">关</el-radio>
              </div>
            </div>
            <!-- 降落伞灯 -->
            <div class="police_controls">
              <div class="plice_fontTwo">降落伞灯:</div>
              <div>
                <el-radio v-model="parachute" label="1" @change="police_jiang(3, 1)">开</el-radio>
                <el-radio v-model="parachute" label="2" @change="police_jiang(3, 2)">关</el-radio>
              </div>
            </div>
            <!-- 隐蔽模式 -->
            <div class="police_controls">
              <div class="plice_fontTwo">隐蔽模式:</div>
              <div>
                <el-radio v-model="take" label="1" @change="police_yin(4, 1)">开</el-radio>
                <el-radio v-model="take" label="2" @change="police_yin(4, 2)">关</el-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
    <!-- 操作区域 -->
    <Dialog
      title="操作区域"
      titleCanMove
      :isShowClose="true"
      class="action"
      :containerStyle="{
        width: '550px',
        top: '208px',
        right: '54px',
        position: 'absolute',
      }"
      :visible.sync="menuList[3].show"
      v-if="menuList[3].show"
    >
      <div class="operation">
        <div class="operation-box" v-for="item in operationList" :key="item.id" @click="add(item)">
          <div>
            <img :src="item.image" />
          </div>
          <span>{{ item.title }}</span>
        </div>
      </div>
    </Dialog>
    <!-- 挂载 -->
    <Dialog
      title="挂载面板"
      titleCanMove
      :isShowClose="true"
      class="mount"
      :containerStyle="{
        width: '270px',
        top: '155px',
        right: '54px',
        position: 'absolute',
      }"
      :visible.sync="menuList[2].show"
      v-if="menuList[2].show"
    >
      <div class="device-box">
        <div
          class="device-box-item"
          v-for="(mount, index) in mountedList"
          :class="{ 'device-box-item_style': mount.style }"
          :key="index"
          @click="handleMount(mount)"
        >
          <el-tooltip :content="mount.title" placement="top" effect="light">
            <img :src="mount.icon" style="width: 100%; height: 100%" />
          </el-tooltip>
        </div>
      </div>
    </Dialog>
    <div class="keyControl" v-if="keyControlFlag">
      <div class="head">
        <div class="tc title">机身控制</div>
        <div class="tr cp" @click="keyControlFlag = false">关闭</div>
        <!-- <div class="w200">
        机身控制
        </div>
        <div>
        云台控制
        </div>-->
      </div>
      <div class="content">
        <div>
          <div>
            <div
              :class="{ active: keycode == 'q' }"
              title="机身左转"
              @mousedown="clickControl({ key: 'q' })"
              @mouseup="cancelControl()"
            >
              <img src="./img/share.png" alt />
              Q
            </div>
            <div
              :class="{ active: keycode == 'w' }"
              title="前进"
              @mousedown="clickControl({ key: 'w' })"
              @mouseup="cancelControl()"
            >
              <img src="./img/share2.png" alt />
              W
            </div>
            <div
              :class="{ active: keycode == 'e' }"
              title="机身右转"
              @mousedown="clickControl({ key: 'e' })"
              @mouseup="cancelControl()"
            >
              <img src="./img/share3.png" alt />
              E
            </div>
            <div
              class="ml12"
              :class="{ active: keycode == 'c' }"
              title="上升"
              @mousedown="clickControl({ key: 'c' })"
              @mouseup="cancelControl()"
            >
              <img src="./img/share7.png" alt />
              C
            </div>
          </div>
          <div class="mt16">
            <div
              :class="{ active: keycode == 'a' }"
              title="左飞"
              @mousedown="clickControl({ key: 'a' })"
              @mouseup="cancelControl()"
            >
              A
              <img src="./img/share4.png" alt />
            </div>
            <div
              :class="{ active: keycode == 's' }"
              title="后退"
              @mousedown="clickControl({ key: 's' })"
              @mouseup="cancelControl()"
            >
              S
              <img src="./img/share5.png" alt />
            </div>
            <div
              :class="{ active: keycode == 'd' }"
              title="右飞"
              @mousedown="clickControl({ key: 'd' })"
              @mouseup="cancelControl()"
            >
              D
              <img src="./img/share6.png" alt />
            </div>
            <div
              class="ml12"
              :class="{ active: keycode == 'z' }"
              title="下降"
              @mousedown="clickControl({ key: 'z' })"
              @mouseup="cancelControl()"
            >
              Z
              <img src="./img/share8.png" alt />
            </div>
          </div>
        </div>
        <div
          class="ml12 cf"
          :class="{active: keycode == 'j'}"
          title="急停"
          @click="clickControl({key:'j'})"
        >J</div>
        <!-- <div class="ml12">
          <div>
            <div :class="{active: keycode == 'j'}" title="急停" @click="clickControl({key:'j'})">J</div>
          </div>
        </div>
        <div class="line ml12"></div>
        <div class="ml12">
          <div>
            <div :class="{active: keycode == 'ArrowUp'}" title="镜头向上" @mousedown="clickControl({key:'ArrowUp'})" @mouseup="cancelControl">
              <img src="./img/向上.png" alt="">
            </div>
          </div>
          <div>
            <div :class="{active: keycode == 'ArrowLeft'}" title="镜头向左" @mousedown="clickControl({key:'ArrowLeft'})" @mouseup="cancelControl">
              <img src="./img/向左.png" alt="">
            </div>
            <div :class="{active: keycode == 'ArrowDown'}" title="镜头向下" @mousedown="clickControl({key:'ArrowDown'})" @mouseup="cancelControl">
              <img src="./img/向下.png" alt="">
            </div>
            <div :class="{active: keycode == 'ArrowRight'}" title="镜头向右" @mousedown="clickControl({key:'ArrowRight'})" @mouseup="cancelControl">
              <img src="./img/向右.png" alt="">
            </div>
          </div>
        </div>
        <div class="ml12">
          <div>
            <div class="f24" :class="{active: keycode == '-'}" title="变焦减小" @mousedown="clickControl({key:'-'})" @mouseup="cancelControl">-</div>
          </div>
          <div class="mt16">
            <div class="f24" :class="{active: keycode == '+' || keycode == '='}" title="变焦增加" @mousedown="clickControl({key:'+'})" @mouseup="cancelControl">+</div>
          </div>
        </div>-->
      </div>
    </div>
    <el-dialog
      title="引导飞行确认"
      :visible.sync="zdydShow"
      width="30%"
      :append-to-body="true"
      :show-close="false"
      :close-on-click-modal="false"
      style="margin-top: 20vh"
    >
      <div class="endrenwu zdfx">
        <div class="queding">位置获取成功，请确认是否进行指点模式飞行</div>
        <div class="fleSpeed">
          指点飞行速度：
          <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="flySpeed"></el-input>
          <span style="opacity: 0;">1</span>(米/秒)
        </div>
        <div class="red" v-if="maxSpeed">指点飞行最大速度为10米/秒</div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="zdydClose">取消</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="zdydThen">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Dialog from "@/components/common/dialog";
import { MountData } from "@/components/device/mount-list";
import API from "@/api";
import methods from "./methods";
import Health from "@/components/health/index.vue";
let Point = null;
let handler = null;

export default {
  components: { Dialog, Health },
  data() {
    return {
      flySpeed: 6, //指点飞行设置
      zdlat: "",
      zdlng: "",
      maxSpeed: false,
      zdfcFlag: true,
      zdydShow: false,
      MountData,
      image: require("@/assets/images/menu/menu1.png"),
      show: false,
      showBorder: false,
      isfk: 1,
      filetype: 0,
      mountedList: [],
      filetypev2: 1,
      // 警灯控制--
      taillights: 0, // 警灯模式
      weideng: 0, // 尾灯
      parachute: 0, // 降落伞邓
      take: 0, //隐蔽模式
      menuList: [
        {
          id: 9,
          title: "健康管理",
          image: require("@/assets/images/menu/hea.png"),
          show: false,
          showBorder: false
        },
        // {
        //   id: 8,
        //   title: "视图库",
        //   image: require("@/assets/images/menu/stk.png"),
        //   show: false,
        //   showBorder: false,
        // },
        {
          id: 7,
          title: "警灯",
          image: require("@/assets/images/menu/light.png"),
          show: false,
          showBorder: false
        },
        // {
        //   id: 1,
        //   title: "任务",
        //   image: require("@/assets/images/menu/menu1.png"),
        //   show: false,
        //   showBorder: false,
        // },
        // {
        //   id: 2,
        //   title: "一键任务",
        //   image: require("@/assets/images/menu/menu2.png"),
        //   show: false,
        //   showBorder: false,
        // },
        // {
        //   id: 12,
        //   title: "一键返航",
        //   image: require("@/assets/images/menu/menu3.png"),
        //   show: false,
        //   showBorder: false,
        // },
        // {
        //   id: 11,
        //   title: "安全降落",
        //   image: require("@/assets/images/menu/menu4.png"),
        //   show: false,
        //   showBorder: false,
        // },

        {
          id: 6,
          title: "挂载",
          image: require("@/assets/images/menu/menu7.png"),
          show: false,
          showBorder: false
        },
        {
          id: 7,
          title: "无人机",
          image: require("@/assets/images/menu/menu6.png"),
          show: false,
          showBorder: false
        },
        {
          id: 5,
          title: "手动",
          image: require("@/assets/images/menu/menu5.png"),
          show: false,
          showBorder: false
        }
      ],
      keyControlFlag: false, //键盘控制
      controlSet: null,
      keycode: null,
      operationList: [
        {
          id: 80,
          title: "定位",
          image: require("@/assets/images/menu/operation1.png"),
          show: false
        },
        {
          id: 18,
          title: "航线模式",
          image: require("@/assets/images/menu/operation2.png"),
          show: false
        },
        {
          id: 17,
          // title: "视频模式",
          title: "暂停飞行",
          image: require("@/assets/images/menu/operation3.png"),
          show: false
        },
        {
          id: 81,
          title: "继续飞行",
          image: require("@/assets/images/menu/operation4.png"),
          show: false
        },
        {
          id: 99,
          title: "任务结束",
          image: require("@/assets/images/menu/operation5.png"),
          show: false
        },
        {
          id: 100,
          title: "运行日志",
          image: require("@/assets/images/menu/yxrz.png"),
          show: false
        },
        {
          id: 12,
          title: "一键返航",
          image: require("@/assets/images/menu/menu3.png"),
          show: false
        },
        {
          id: 11,
          title: "安全降落",
          image: require("@/assets/images/menu/menu4.png"),
          show: false
        },
        {
          id: 110,
          title: "键盘",
          image: require("@/assets/images/menu/jp.png"),
          show: false
        },
        {
          id: 111,
          title: "指点飞行",
          image: require("@/assets/images/menu/operation2.png"),
          show: false
        }
      ],
      options: [
        {
          value: "1",
          label: "选择1"
        }
      ],
      drd: {
        flightLine: []
      },
      value: "1",
      flightLineId: null
    };
  },
  // MountData.list
  methods: {
    zdydThen() {
      if (this.flySpeed > 10) {
        this.maxSpeed = true;
      } else {
        this.$store.dispatch("mqtt/publishMessage", {
          topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
          data: {
            type: 518,
            data: {
              latitude: this.zdlat,
              longitude: this.zdlng,
              speed: Number(this.flySpeed)
            }
          }
        });

        // this.$emit("fn", 111,);
        handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        this.zdfcFlag = true;
        this.zdydShow = false;
        this.maxSpeed = false;
        this.flySpeed = 6;
      }
    },
    zdydClose() {
      if (Point) {
        window.viewer.entities.remove(Point);
      }
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      this.$message({
        type: "info",
        message: "已取消操作"
      });
      this.zdfcFlag = true;
      this.zdydShow = false;
      this.maxSpeed = false;
      this.flySpeed = 6;
    },
    getcameraPosInfo() {
      // 获取 相机姿态信息
      var head = viewer.scene.camera.heading;
      var pitch = viewer.scene.camera.pitch;
      var roll = viewer.scene.camera.roll;
      var info = { head: head, pitch: pitch, roll: roll };
      // 获取位置 wgs84的地心坐标系，x,y坐标值以弧度来表示
      var position = viewer.scene.camera.positionCartographic; //with longitude and latitude expressed in radians and height in meters.
      //以下方式也可以获取相机位置只是返回的坐标系不一样
      // var position = viewer.scene.camera.position //cartesian3 空间直角坐标系
      // var ellipsoid = scene.globe.ellipsoid;
      // var position =ellipsoid.cartesianToCartographic(viewer.scene.camera.position)//
      // 弧度转经纬度
      var longitude = Cesium.Math.toDegrees(position.longitude).toFixed(6);
      var latitude = Cesium.Math.toDegrees(position.latitude).toFixed(6);
      var height = position.height;
      return { lng: longitude, lat: latitude, h: height, mat: info };
    },
    addModeelPoint(viewer, position, name) {
      return viewer.entities.add({
        position,
        point: {
          pixelSize: 12,
          color: Cesium.Color.fromCssColorString("#ee0000"),
          outlineColor: Cesium.Color.fromCssColorString("#fff"),
          outlineWidth: 1,
          show: true
        },
        label: {
          text: name,
          scale: 0.5,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          outlineWidth: 0,
          pixelOffset: new Cesium.Cartesian2(26, -20)
        }
      });
    },
    getcameraPosInfo() {
      // 获取 相机姿态信息
      var head = viewer.scene.camera.heading;
      var pitch = viewer.scene.camera.pitch;
      var roll = viewer.scene.camera.roll;
      var info = { head: head, pitch: pitch, roll: roll };
      // 获取位置 wgs84的地心坐标系，x,y坐标值以弧度来表示
      var position = viewer.scene.camera.positionCartographic; //with longitude and latitude expressed in radians and height in meters.
      //以下方式也可以获取相机位置只是返回的坐标系不一样
      // var position = viewer.scene.camera.position //cartesian3 空间直角坐标系
      // var ellipsoid = scene.globe.ellipsoid;
      // var position =ellipsoid.cartesianToCartographic(viewer.scene.camera.position)//
      // 弧度转经纬度
      var longitude = Cesium.Math.toDegrees(position.longitude).toFixed(6);
      var latitude = Cesium.Math.toDegrees(position.latitude).toFixed(6);
      var height = position.height;
      return { lng: longitude, lat: latitude, h: height, mat: info };
    },
    async zdfx() {
      console.log(!this.zdfcFlag, "!this.zdfcFlag");
      let viewer = window.viewer;
      if (!this.zdfcFlag) {
        return false;
      }
      // 指点飞行 wgs84
      this.$message({
        type: "info",
        message: "请点击地图获取目标位置"
      });
      this.zdfcFlag = false;
      let position = null;
      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction(movement => {
        let earthPosition = viewer.camera.pickEllipsoid(
          movement.position,
          viewer.scene.globe.ellipsoid
        );
        let cartographic = Cesium.Cartographic.fromCartesian(
          earthPosition,
          viewer.scene.globe.ellipsoid,
          new Cesium.Cartographic()
        );
        let lng = Cesium.Math.toDegrees(cartographic.longitude);
        let lat = Cesium.Math.toDegrees(cartographic.latitude);
        this.zdlat = lat;
        this.zdlng = lng;
        if (Point) {
          window.viewer.entities.remove(Point);
        }
        // let position = window.viewer.scene.camera.pickEllipsoid(
        //   movement.position
        // );
        (position = Cesium.Cartesian3.fromDegrees(lng, lat, 20)),
          (Point = this.addModeelPoint(window.viewer, position, "目标点"));

        let zt = this.getcameraPosInfo();

        // 指点飞行
        this.zdydShow = true;

      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    keyControl() {
      let data = null;
      let num = 0;
      document.addEventListener("keydown", event => {
        if (this.keyControlFlag) {
          console.log("键值：" + event.keyCode);
          console.log("键：" + event.key);
          this.keycode = event.key;
          //return
          if (!(num % 10)) {
            if (event.key == "q" || event.keyCode == 81) {
              data = {
                data: {
                  leftTurn: 10
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data
              });
            } else if (event.key == "w" || event.keyCode == 87) {
              data = {
                data: {
                  front: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data
              });
            } else if (event.key == "e" || event.keyCode == 69) {
              data = {
                data: {
                  rightTurn: 10
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data
              });
            } else if (event.key == "a" || event.keyCode == 65) {
              data = {
                data: {
                  left: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data
              });
            } else if (event.key == "s" || event.keyCode == 83) {
              data = {
                data: {
                  back: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data
              });
            } else if (event.key == "d" || event.keyCode == 68) {
              data = {
                data: {
                  right: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data
              });
            } else if (event.key == "c" || event.keyCode == 67) {
              data = {
                data: {
                  up: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data
              });
            } else if (event.key == "z" || event.keyCode == 90) {
              data = {
                data: {
                  down: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data
              });
            }
          }
          if (event.key == "j" || event.keyCode == 74) {
            // this.$emit("fn", { type: 513, data: "POSITION"},"wrj");
            this.$store.dispatch("mqtt/publishMessage", {
              topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
              data: { type: 513, data: "POSITION" }
            });
          }
          //   if(this.selected_mount){
          //     if (event.key == "k" || event.keyCode == 75) {
          //       this.$refs.mountRef.change_control_modeV2(0)
          //     }else if (event.key == "l" || event.keyCode == 76) {
          //       this.$refs.mountRef.change_control_modeV2(2)
          //     }else if (event.key == "" || event.keyCode == 32) {
          //       this.$refs.mountRef.handle_take_photo()
          //     } else if (event.key == "-" || event.keyCode == 109 || event.keyCode == 187) {
          //       let nums = localStorage.getItem(this.device.deviceHardId) || 5
          //       if(nums >= 2){
          //         this.$refs.mountRef.handle_zoom(-1)
          //       }
          //     }else if(event.key == "+" || event.keyCode == 107){
          //       let nums = localStorage.getItem(this.device.deviceHardId) || 5
          //       if(nums <= 200){
          //         this.$refs.mountRef.handle_zoom(1)
          //       }
          //     }else if (event.keyCode === 37 || event.key === "ArrowLeft") {
          //       // 左箭头按下
          //       this.$refs.mountRef.fangxiang("left")
          //     } else if (event.keyCode === 38 || event.key === "ArrowUp") {
          //       // 上箭头按下
          //       this.$refs.mountRef.fangxiang("up")
          //     } else if (event.keyCode === 39 || event.key === "ArrowRight") {
          //       // 右箭头按下
          //       this.$refs.mountRef.fangxiang("right")
          //     } else if (event.keyCode === 40 || event.key === "ArrowDown") {
          //       // 下箭头按下
          //       this.$refs.mountRef.fangxiang("down")
          //     }
          // }
        }
        num++;
      });
      document.addEventListener("keyup", event => {
        this.keycode = "";
        // if (this.keyControlFlag) {
        //   this.$refs.mountRef.stopChange();
        //   this.$refs.mountRef.stopfxFn();
        // }
      });
    },
    clickControl(event) {
      let data = null;
      let num = 0;
      this.controlSet = setInterval(() => {
        if (this.keyControlFlag) {
          this.keycode = event.key;
          // return
          if (!(num % 10)) {
            if (event.key == "q" || event.keyCode == 81) {
              data = {
                data: {
                  leftTurn: 10
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data: data
              });
            } else if (event.key == "w" || event.keyCode == 87) {
              data = {
                data: {
                  front: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data: data
              });
            } else if (event.key == "e" || event.keyCode == 69) {
              data = {
                data: {
                  rightTurn: 10
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data: data
              });
            } else if (event.key == "a" || event.keyCode == 65) {
              data = {
                data: {
                  left: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data: data
              });
            } else if (event.key == "s" || event.keyCode == 83) {
              data = {
                data: {
                  back: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data: data
              });
            } else if (event.key == "d" || event.keyCode == 68) {
              data = {
                data: {
                  right: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data: data
              });
            } else if (event.key == "c" || event.keyCode == 67) {
              data = {
                data: {
                  up: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data: data
              });
            } else if (event.key == "z" || event.keyCode == 90) {
              data = {
                data: {
                  down: 100
                },
                type: 538
              };
              this.$store.dispatch("mqtt/publishMessage", {
                topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
                data: data
              });
            }
          }
          if (event.key == "j" || event.keyCode == 74) {
            // this.$emit("fn", { type: 513, data: "POSITION" }, "wrj");
            setTimeout(() => {
              this.keycode = "";
              clearInterval(this.controlSet);
              this.controlSet = null;
            }, 500);
          }
          //   if(this.selected_mount){
          //     if (event.key == "k" || event.keyCode == 75) {
          //       this.$refs.mountRef.change_control_modeV2(0)
          //     }else if (event.key == "l" || event.keyCode == 76) {
          //       this.$refs.mountRef.change_control_modeV2(2)
          //     }else if (event.key == "" || event.keyCode == 32) {
          //       this.$refs.mountRef.handle_take_photo()
          //     }else if(event.key == "-" || event.keyCode == 109){
          //       let nums = localStorage.getItem(this.device.deviceList[0].deviceHardId) || 5
          //       if(nums >= 2){
          //         this.$refs.mountRef.handle_zoom_reduce()
          //         this.$refs.mountRef.stopChange()
          //       }
          //     }else if(event.key == "+" || event.keyCode == 107){
          //       let nums = localStorage.getItem(this.device.deviceList[0].deviceHardId) || 5
          //       if(nums <= 200){
          //         this.$refs.mountRef.handle_zoom_plus()
          //         this.$refs.mountRef.stopChange()
          //       }
          //     }else if (event.keyCode === 37 || event.key === "ArrowLeft") {
          //       // 左箭头按下
          //       this.$refs.mountRef.fangxiang("right")
          //     } else if (event.keyCode === 38 || event.key === "ArrowUp") {
          //       // 上箭头按下
          //       this.$refs.mountRef.fangxiang("up")
          //     } else if (event.keyCode === 39 || event.key === "ArrowRight") {
          //       // 右箭头按下
          //       this.$refs.mountRef.fangxiang("left")
          //     } else if (event.keyCode === 40 || event.key === "ArrowDown") {
          //       // 下箭头按下
          //       this.$refs.mountRef.fangxiang("down")
          //     }
          // }
        }
        num++;
      }, 300);
    },
    cancelControl() {
      this.keycode = "";
      clearInterval(this.controlSet);
      this.controlSet = null;
      // if (this.keyControlFlag) {
      //   this.$refs.mountRef.stopChange()
      //   this.$refs.mountRef.stopfxFn()
      // }
    },
    // async FlightLine() {  // 获取航线下拉数据
    //   const { data } = await API.TASK.Drd()
    //   this.drd.flightLine = data
    // },
    // 警灯控制
    // xing:类型：1警灯，2尾灯，3降落伞灯，4隐蔽
    // num：警灯模式下：1关闭，2慢闪，3快闪，4交替闪，其他模式为1打开，2关闭
    police_kong(xing, num) {
      let datas = {
        data: {
          param1: xing,
          param2: num
        },
        type: 531
      };
      // this.publish(topic, this.datas, 212);
      this.$store.dispatch("mqtt/publishMessage", {
        topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
        data: { type: 513, data: datas }
      });
    },
    ...methods,
    // 尾灯判定
    police_wei(xing, num) {
      let datas = {
        data: {
          param1: xing,
          param3: num
        },
        type: 531
      };
      this.$store.dispatch("mqtt/publishMessage", {
        topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
        data: { type: 513, data: datas }
      });
    },
    // 降落伞灯
    police_jiang(xing, num) {
      let datas = {
        data: {
          param1: xing,
          param3: num
        },
        type: 531
      };
      this.$store.dispatch("mqtt/publishMessage", {
        topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
        data: { type: 513, data: datas }
      });
    },
    // 隐蔽模式
    police_yin(xing, num) {
      let datas = {
        data: {
          param1: xing,
          param4: num
        },
        type: 531
      };
      this.$store.dispatch("mqtt/publishMessage", {
        topic: "PX4/OBTAIN/" + this.uav.deviceHardId,
        data: { type: 513, data: datas }
      });
    },
    resetmenuList(id) {
      this.menuList.forEach(item => {
        if (item.id != id) {
          item.show = false;
          item.showBorder = false;
        }
      });
    },
    add(item) {
      this.resetmenuList(item.id);
      if (item.id == 9 || item.id == 8 || item.id == 7 || item.id == 6) {
        item.showBorder = !item.showBorder;
        item.show = !item.show;
      }
      //前三个点击
      if (item.id == 12 || item.id == 11 || item.id == 5) {
        //是否为点击后的状态
        if (!item.showBorder) {
          this.$confirm(
            `确定要${item.showBorder ? "取消" : "执行"}${item.title}?`,
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }
          )
            .then(() => {
              this.$emit("fn", item.id);
              this.$message({
                type: "success",
                message: `执行${item.title}成功!`
              });
              item.show = !item.show;
            })
            .catch(() => {
              return this.$message({
                type: "info",
                message: `已取消执行${item.title}`
              });
            });
        } else {
          // item.showBorder = !item.showBorder;
        }
      } else if (item.id == 110) {
        this.keyControlFlag = !this.keyControlFlag;
      } else if (item.id == 111) {
        console.log("指点飞行");
        this.zdfx();
        // 指点飞行
      } else {
        this.$emit("fn", item.id);
      }
    },
    handleMount(e) {
      this.$emit("on-change", e);
      this.menuList[3].show = false;
    },
    closemenu3() {
      this.menuList[3].showBorder = false;
    }
  },
  watch: {
    mount(val) {
      if (val && val.gimbalName) {
        let mountList = this.MountData.list.map(item => {
          if (item.name == val.gimbalName) {
            return {
              ...item,
              style: true
            };
          } else {
            return {
              ...item,
              style: false
            };
          }
        });
        this.mountedList = mountList;
      }
    }
  },
  beforeDestroy() {
    if (Point) {
      window.viewer.entities.remove(Point);
    }
  },
  computed: {
    uav() {
      return this.$store.state.mqtt.uav;
    },
    mount() {
      return this.$store.state.mqtt.mountList;
    }
  },
  mounted() {
    this.mountedList = this.MountData.list;
    this.keyControl();
  }
};
</script>

<style lang="scss" scoped>
.flexright-menu {
  position: fixed;
  top: 150px;
  right: 44px;
  z-index: 1;

  .flexright-menu-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: rgba(9, 32, 87, 0.7);
    border: 1px solid transparent;
    margin-bottom: 2px;

    &:hover {
      border: 1px solid #43deff;
      cursor: pointer;
    }

    img {
      width: 22px;
      height: 22px;
    }

    span {
      margin-top: 3px;
      // width: 16px;
      height: 10px;
      font-size: 12px;
      transform: scale(0.8);
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 10px;
    }
  }

  .flexright-menu-box:nth-child(5) {
    // margin-bottom: 21px;
  }

  .showBorder {
    border-color: #43deff;
  }

  .operation {
    display: flex;
    justify-content: space-evenly;

    .operation-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      background-image: url(~@/assets/platform/aeronautical/devbg.png);
      border-radius: 5px;

      &:hover {
        cursor: pointer;
      }

      img {
        width: 22px;
        height: 22px;
      }

      span {
        // width: 20px;
        height: 14px;
        font-size: 10px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 14px;
      }
    }
  }

  .device-box {
    height: 36px;
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 131px;

    .device-box-item {
      width: 36px;
      height: 36px;
      margin: 0 6px;
      background-image: url(~@/assets/device/moutbg.png);
      border-radius: 4px;
      cursor: pointer;
    }

    .device-box-item_style {
      border: 1px solid red;
    }

    .device-box-item:hover {
      opacity: 0.7;
    }
  }

  .stk {
    width: 470px;
    height: 378px;
    background: rgba(12, 34, 73, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(26, 92, 246, 0.5);
    backdrop-filter: blur(1px);

    .stk_head {
      display: flex;
      cursor: pointer;

      div {
        padding: 0 8px;
        // width: 243px;
        width: 100%;
        text-align: center;
        line-height: 32px;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        line-height: 21px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      }

      .ac {
        background: linear-gradient(
          180deg,
          #9198ff 0%,
          rgba(45, 81, 153, 0.45) 40%,
          #05091a 100%
        );
        box-shadow: inset 0px 0px 10px 2px #3f9dff;
        border-radius: 10px 0px 0px 0px;
        border: 1px solid #427dff;
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .bc {
        background: linear-gradient(
          180deg,
          #9198ff 0%,
          rgba(45, 81, 153, 0.45) 40%,
          #05091a 100%
        );
        box-shadow: inset 0px 0px 10px 2px #3f9dff;
        border-radius: 0px 10px 0px 0px;
        border: 1px solid #427dff;
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .content {
      margin-top: 10px;
      padding: 0 16px;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btns {
          width: 64px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          background: url("~@/assets/images/font/zc.png") no-repeat;
          background-size: 100% 100%;
          cursor: pointer;

          // &:hover {
          //   background: url("~@/assets/images/font/qx.png") no-repeat;
          //   background-size: 100% 100%;
          // }
        }

        .bg {
          background: url("~@/assets/images/font/qx.png") no-repeat;
          background-size: 100% 100%;
        }

        .blue {
          background: url("~@/assets/images/font/qx.png") no-repeat;
          background-size: 100% 100%;
        }

        .select {
          display: flex;
          align-items: center;

          .iconfont {
            color: #fff;
          }

          .btns {
            margin-right: 16px;
            width: 64px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #ffffff;
            background: url("~@/assets/images/font/zc.png") no-repeat;
            background-size: 100% 100%;

            // &:hover {
            //   background: url("~@/assets/images/font/qx.png") no-repeat;
            //   background-size: 100% 100%;
            // }
          }

          .bg {
            background: url("~@/assets/images/font/qx.png") no-repeat;
            background-size: 100% 100%;
          }

          .el-select {
            width: 80px;
            height: 32px;

            /deep/.el-input--suffix {
              width: 100%;
              height: 100%;

              .el-input__inner {
                width: 100%;
                height: 100%;
              }
            }

            /deep/.el-input__suffix {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .lists {
        // margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .boxs {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        height: 280px;
        overflow: auto;
        margin-top: 14px;

        .task {
          display: flex;
          flex-direction: column;

          .name {
            color: #fff;
          }

          .images {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            // height: 280px;
            overflow: hidden;
            overflow-y: auto;
            margin-top: 14px;

            .box {
              position: relative;
              width: 135px;
              height: 88px;
              background: #ffffff;
              border-radius: 2px;
              margin-left: 8px;
              margin-bottom: 14px;

              .el-image {
                width: 138px;
                height: 88px;
                background: #ffffff;
                border-radius: 2px;
              }

              img {
                width: 135px;
                height: 88px;
              }

              .down {
                position: absolute;
                bottom: 0;
                right: -3px;
                width: 30px;
                height: 20px;
                text-align: center;
                line-height: 20px;
                background: rgba($color: #000000, $alpha: 0.6);
                color: #fff;
                font-size: 12px;
                display: none;
              }

              &:hover {
                .down {
                  display: block;
                }
              }
            }

            .boxv2 {
              width: 430px;
              height: 120px;
              margin-left: 0;

              video {
                width: 430px;
                height: 120px;
              }
            }
          }
        }
      }
    }
  }
}

// 警灯弹框
.police {
  width: 475px;
  height: 200px;
  backdrop-filter: blur(2px);
  pointer-events: auto;

  .police_head {
    height: 30px;
    border-bottom: 1px solid #70daf9;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 10px;
    align-items: center;
    box-sizing: border-box;

    .police_name {
      display: flex;

      .police_icon {
        width: 5px;
        height: 15px;
        background: #ffbd36;
        margin: 0 10px 0 0;
      }
    }
  }

  .police_ceonter {
    width: 100%;
    height: calc(100% - 30px);
    padding: 24px;
    box-sizing: border-box;

    .police_controls {
      display: flex;
      margin: 0 0 15px 0;
    }
  }
}

.police_font {
  font-weight: 700;
  color: #2ddcfc;
}

.plice_fontTwo {
  width: 90px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

::v-deep
  .linear-form
  .el-form-item
  .el-form-item__content
  .el-input
  .el-input__inner {
  width: 100%;
}

.linear-form {
  .form-submit-item {
    position: relative;

    .create-btn {
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 5px;
      width: 70px;
      height: 16px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #14d1ff;
      line-height: 16px;
    }
  }
}

::v-deep {
  .el-radio__label {
    color: #fff;
  }

  flexright-menu {
    .mount {
      .dialog-body {
        margin: 10px 0;
      }

      .action {
        .dialog-body {
          margin: 5px 0;
        }
      }
    }
  }
}
.keyControl {
  position: fixed;
  right: 100px;
  bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  height: 120px;
  background: rgba(9, 32, 87, 0.7);
  border-radius: 10px;
  padding: 8px;
  .head {
    width: 360px;
    color: #fff;
    text-indent: 16px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    .title {
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: YouSheBiaoTiHei;
      font-size: 18px;
    }
  }
  .content {
    display: flex;
    align-items: center;
    > div {
      flex-direction: column;
      display: flex;
      align-items: center;
      > div {
        display: flex;
        div {
          background: rgba(9, 32, 87, 0);
          width: 32px;
          height: 32px;
          font-family: MicrosoftYaHei;
          font-size: 16px;
          color: #ffffff;
          font-style: normal;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .active {
          background: #631cce;
        }
      }
    }
    .line {
      width: 1px;
      height: 60px;
      border: 1px solid;
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        )
        1 1;
    }
  }
}
// 弹框样式
.endrenwu {
  width: 100%;
  height: 177px;
}

.zdfx {
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .fleSpeed {
    margin-top: 16px;
    color: #fff;
    display: flex;
    white-space: nowrap;
    align-items: center;
    /deep/.el-input__inner {
      background: transparent;
      color: #fff;
    }
  }
  .red {
    margin-top: 8px;
    color: red;
  }
  .btn {
    margin-top: 16px !important;
  }
}
.queding {
  color: #92d9ff;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 30px 0 0 0;
}
.tishiyu {
  color: #92d9ff;
  font-size: 18px;
  margin: 30px 0 0 0;
  text-align: center;
  width: 100%;
}
.btn_kuang {
  display: flex;
  justify-content: center;
  width: 100%;
}
.btn {
  width: 79px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  margin: 20px 0 0 0;
  cursor: pointer;
}
.btn_lan {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #606266;
}
.btn_lv {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #fff;
}
</style>
