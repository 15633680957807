<template>
  <div class="big-box" :class="telescopic2 ? 'telescopic2' : 'telescopics2'">
    <Dialog
      :isShowClose="false"
      :isShowTitle="false"
      :containerStyle="{
        width: '500px',
        zIndex: 10,
      }"
      class="taskDia"
    >
      <Search @on-search="handle_search" />
      <div :class="telescopic ? 'telescopic' : 'telescopics'">
        <Item v-for="item in list" :key="item.id" :data="item" />
      </div>
      <div class="ssanniu" @click="stretch(1)">
        <div v-if="this.telescopic" class="up"></div>
        <div v-else class="down"></div>
      </div>
    </Dialog>
    <div class="ssanniu2_box">
      <div class="ssanniu2" @click="stretch(2)">
        <div v-if="this.telescopic2" class="left"></div>
        <div v-else class="right"></div>
      </div>
    </div>

    <Dialog v-if="type === 'task'" :isShowClose="false" :isShowTitle="false" :containerStyle="{
      width: '500px',
      height: '355px',
      top: '10px',
      left: '4px',
      zIndex: 10,
      position: 'absolute'
    }" class="task">
    </Dialog>
    <!-- 任务详情弹框 -->
    <Details
      v-if="Details_List"
      @on-closeDetails="closeDetails"
      :Details_List="Details_List"
    />
  </div>
</template>

<script>
import Dialog from "@/components/common/dialog";
import Table from "@/components/common/table";
import Search from "./search.vue";
import API from "@/api";
import Details from "./details.vue"; // 详情弹框
import Item from "./item";
import message from "./message";

function treeListCopy(tree, list = []) {
  tree.forEach((item) => {
    if (item.devices && item.devices.length > 0) {
      item.devices.forEach((device) => list.push(device));
    }
    if (item.child && item.child.length > 0) {
      treeListCopy(item.child, list);
    }
    if (item.deviceTypes && item.deviceTypes.length > 0) {
      treeListCopy(item.deviceTypes, list);
    }
  });

  return list;
}

export default {
  components: {
    Dialog,
    Table,
    Search,
    Details,
    Item,
  },
  props: {
    float_videos: {
      type: Array,
      default: () => [],
    },
    uav_control: {},
  },
  data() {
    return {
      search: {},
      Details_List: null, // 查看任务详情存的数据，也通过它控制弹框显示隐藏
      telescopic: false, // 上下收缩
      telescopic2: true, // 左右收缩
      columns: [
        { prop: "taskTitle", label: "任务名称", width: "120px" },
        { prop: "taskCateName", label: "任务类型" },
        { prop: "ai", label: "配置AI", width: "100px" },
        { prop: "deviceName", label: "无人机名称", width: "100px" },
        { prop: "action", label: "操作", slot: true },
      ],
      dataSource: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: null,
      status: {
        state: {
          0: "离线",
          1: "在线",
        },
      },
      list: [],
      type: null,
      timeOut: null,
      defaultProps: {
        children: "devices",
        label: "deviceName",
      },
    };
  },
  provide() {
    return {
      task_This: this,
    };
  },
  methods: {
    ...message,
    // -------------------------------------------------------------------------任务详情弹框函数开始
    async showDetailsInfo(id, device) {
      //打开弹框
      console.log("打开弹窗");
      const res = await API.TASK.getInfoByDevice(id);
      console.log(res, "resresres");
      if (res.status === 1 && res.data != null) {
        this.Details_List = res.data;
      } else {
        this.$message.warning("暂无任务!");
        device._airway = false;
      }
    },
    closeDetails() {
      // 关闭弹框
      this.Details_List = null;
    },
    // --------------------------------------------------------------------------任务详情弹框函数结束
    async filter(id) {
      const res = await API.DEVICE.organTree();
      let data = res.data[0].devices.filter((item) => {
        return item.id == id;
      });
      if (id) {
        this.$emit("on-control", data[0]);
      } else {
        let arr = res.data.filter((el) => el.name == "潮科技鹰");
        if (arr.devices.length > 0) {
          this.$emit("on-control", arr.devices[0]);
        }
      }
    },
    async deviceList() {
      let { data: list } = await API.DEVICE.organTree({
        ...this.search,
      });
      if (list && list.length && list[0].child) {
        list[0].child.sort(function (a, b) {
          return a.sort - b.sort;
        });
      }

      function each(tree, flag) {
        return tree.map((item) => ({
          ...item,
          collapse: flag,
          devices:
            item.devices && item.devices.length
              ? item.devices.map((device) => ({
                  ...device,
                  _location: false,
                  _video: false,
                  _airway: false,
                  _control: false,
                  org_id: item.id,
                  // reportStatus: 1
                }))
              : [],
          child: item.child && item.child.length ? each(item.child, true) : [],
          deviceTypes:
            item.deviceTypes && item.deviceTypes.length
              ? item.deviceTypes.map((deviceItem) => ({
                  ...deviceItem,
                  collapse: flag,
                  devices:
                    deviceItem.devices && deviceItem.devices.length
                      ? deviceItem.devices.map((deviceItemDevice) => ({
                          ...deviceItemDevice,
                          _location: false,
                          _video: false,
                          _airway: false,
                          _control: false,
                          org_id: deviceItem.id,
                          // reportStatus: 1
                        }))
                      : [],
                }))
              : [],
        }));
      }

      if (list && list.length) {
        list = each(list);
      }
      this.list = list || [];
      this.$emit("upOnList", list);
      this.timeOut = setTimeout(() => {
        this.resetListStatus();
      }, 10000);
    },
    async resetListStatus() {
      // 拿到在线的无人机 ，改变列表无人机的状态
      let res = await API.DEVICE.onlineDevice();
      console.log(res, "res");
      if (res.code == 200) {
        let ids = res.data.map((item) => {
          return item.id;
        });
        let newlist = treeListCopy(this.list);
        newlist.forEach((item) => {
          let flag = ids.indexOf(item.id);
          if (flag != -1) {
            item.reportStatus = 1;
          } else {
            item.reportStatus = 0;
          }
        });
      }
    },
    changeStatus(id, type, status) {
      if ((id, type)) {
        let newlist = treeListCopy(this.list);
        let data = newlist.filter((item) => item.id == id);
        if (data.length > 0) {
          data[0][type] = status;
        }
      }
    },
    // 定时获取设备列表
    async async_get_list(num) {
      const res = await API.DEVICE.organTree({
        ...this.search,
      });
      if (res.status === 1) {
        let { data } = res;
        if (data[0].devices.length > 0 || num === 1) {
          this.tableData = data;
          this.tableData.map((item) => (item.deviceName = item.anotherName));
          let list = [];
          data[0].devices.map((item) => {
            if (item.reportStatus === 1 && item.deviceHardId) {
              list.push(item.deviceHardId);
            }
          });
          list = [...new Set(list)];
          this.$emit("upOnList", list);
        }
        this.timeOut = setTimeout(() => {
          this.async_get_list();
        }, 10000);
      } else {
        this.$message.error(res.message);
        this.timeOut = setTimeout(() => {
          this.async_get_list();
        }, 10000);
      }
    },
    // 定时获取任务列表
    async async_get_list_task() {
      const { page, pageSize } = this.pagination;
      const res = await API.TASK.intuitive({
        taskStatus: 3,
        pageNum: page,
        pageSize: pageSize,
      });
      if (res.status === 1) {
        const data = res.data;
        this.dataSource = data.data || [];
        this.pagination.page = data.pageNo;
        this.pagination.total = data.totalCount;
        let list = [];
        this.dataSource.map((item) => {
          if (item.reportStatus === 1 && item.deviceHardId) {
            list.push(item.deviceHardId);
          }
        });
        list = [...new Set(list)];
        this.$emit("upOnList", list);
        this.timeOut = setTimeout(() => {
          this.async_get_list_task();
        }, 10000);
      } else {
        this.$message.error(res.message);
        this.timeOut = setTimeout(() => {
          this.async_get_list_task();
        }, 10000);
      }
    },

    handle_search(data) {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.search = data;
      this.deviceList(1);
    },
    handleNodeClick(data) {},
    // 无人机列表伸缩按钮
    stretch(data) {
      if (data === 1) {
        this.telescopic = !this.telescopic;
      } else if (data === 2) {
        this.telescopic2 = !this.telescopic2;
        this.$store.commit('device/SET_STATE', { key: 'telescopic2', value: this.telescopic2 })
        this.$emit("chageVdieoPost");
      }
    },
  },
  mounted() {
    this.type = this.$route.params.type;
    this.deviceList();
    if (this.type === "task") {
      this.async_get_list_task();
    } else {
      // this.async_get_list();
    }
  },
  destroyed() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-table__expand-icon {
    color: #488cff !important;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .taskDia {
  transition: all 500ms linear;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    rgba(19, 53, 111, 0.8) 0%,
    rgba(8, 28, 64, 0.8) 100%
  );
  border: 3px solid;
  border-image: linear-gradient(
      44deg,
      rgba(70, 182, 255, 0),
      rgba(85, 158, 202, 1),
      rgba(153, 215, 255, 0)
    )
    2 2;

  .el-table__expanded-cell {
    background: radial-gradient(rgba(0, 0, 0, 0), transparent);
  }

  .el-table::before {
    display: none;
  }

  .tableData {
    height: 100%;
    overflow: auto;
    background: radial-gradient(rgba(0, 0, 0, 0), transparent);

    .el-table tr {
      background: radial-gradient(rgba(0, 0, 0, 0), transparent);
    }

    .el-table__row {
      background: radial-gradient(rgba(0, 0, 0, 0), transparent);
    }

    .el-form {
      .el-form-item {
        margin-bottom: 0;

        .el-form-item__content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          max-width: 120px;
        }
      }
    }
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background: radial-gradient(rgba(0, 0, 0, 0), transparent);
  }

  .el-table {
    td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }

    tr {
      background: radial-gradient(rgba(0, 0, 0, 0), transparent);
    }
  }

  .table-expand {
    .el-form-item {
      margin-left: 10px;
      width: 45%;
    }
  }

  .el-form-item__label {
    color: #809ac5;
    width: 84px;
  }

  .el-table--enable-row-transition .el-table__body td.el-table__cell {
    padding-top: 0px;
    background: radial-gradient(rgba(0, 0, 0, 0), transparent);
  }

  .table-pagination {
    color: #93b6f0;
  }
}

::v-deep .task {
  .dialog-body {
    .table-pagination {
      .el-pagination__total {
        color: #9bbff8;
      }

      .btn-prev {
        color: #9bbff8;
      }

      .btn-next {
        color: #9bbff8;
      }

      .el-pagination__jump {
        color: #9bbff8;

        .el-input__inner {
          color: #9bbff8;
        }
      }
    }
  }
}

input.textinput {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #a8bdd4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
}

.lxzt {
  color: #009aff;
}

.zxzt {
  color: rgb(22, 217, 121);
}

.lbbox {
  .el-form-item__label {
    text-align: right;
  }
}

::v-deep .cpt-uav-warning-search {
  margin-bottom: 10px;
}

::v-deep .el-tree {
  color: #bbcff0;
  background-color: rgba(0, 0, 0, 0);
  overflow-y: auto;
  height: calc(100% - 45px);
}

::v-deep .el-tree-node__content:hover {
  background: rgba(3, 24, 49, 0.5);
}

::v-deep .el-tree-node__content:active {
  color: #14d1ff !important;
  // background: rgba(3, 24, 49, 0.5);
}

.ys {
  color: #10f181;
}

::v-deep
  .taskDia
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  color: #14d1ff !important;
  background: rgba(3, 24, 49, 0.5);
}

::v-deep
  .taskDia
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  color: #14d1ff !important;
  background: rgba(3, 24, 49, 0.5);
}

::v-deep .taskDia .el-tree-node:focus > .el-tree-node__content {
  color: #14d1ff !important;
  background: rgba(3, 24, 49, 0.5);
}

.span-box {
  display: flex;
  justify-content: space-between;
  width: 90%;

  .span-box-color {
    color: #009aff;
  }
}

::v-deep .cpt-service-dialog .dialog-content .dialog-body {
  background: rgba(0, 0, 0, 0);
}

.tbwbj {
  span {
    color: #bbcff0;
    margin-right: 10px;
  }
}

span:hover {
  color: #009aff;
}

.ssanniu {
  width: 195px;
  height: 14px;
  position: absolute;
  left: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: pink;
  cursor: pointer;
  background: url(~@/assets/platform/ssanniu.png) no-repeat;

  .up {
    width: 16px;
    height: 9px;
    margin-right: 4px;
    background: url(~@/assets/platform/xxsoushuo.png) no-repeat;
  }

  .down {
    width: 16px;
    height: 9px;
    background: url(~@/assets/platform/xssoushuo.png) no-repeat;
  }
}

.ssanniu2_box {
  position: absolute;
  top: 23%;
  right: -6px;

  .ssanniu2 {
    width: 20px;
    height: 155px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: url(~@/assets/platform/sqanniu.png) no-repeat;

    // background-size: cover;
    .left {
      width: 9px;
      height: 16px;
      margin-right: 4px;
      background: url(~@/assets/platform/xssLeft.png) no-repeat;
    }

    .right {
      width: 9px;
      height: 16px;
      background: url(~@/assets/platform/xssRight.png) no-repeat;
    }
  }
}

.telescopic {
  height: 555px;
  overflow: auto;
}

.telescopics {
  height: 355px;
  overflow: auto;
}

.telescopic2 {
  height: 555px;
  left: 27px !important;
}

.telescopics2 {
  height: 355px;
  left: -506px !important;
}

.big-box {
  width: 500px;
  height: 335px;
  top: 80px;
  left: 27px;
  z-index: 10;
  position: relative;
  padding-right: 20px;
  transition: all 400ms linear;
}
</style>
