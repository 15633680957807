<template>
  <div class="float">
    <div
      @click="clickShadow"
      :style="videoPostion ? videoPosRight : videoPosLeft"
    >
      <Dialog
        v-interact="{ resize: [true, true, true, true], el: 3 }"
        :visible.sync="visible"
        v-if="visible"
        :title="title"
        style="min-width: 416px; max-height: 99%"
        class="videoLog"
        :style="
          boxShadow.shadow ? 'box-shadow: #0067ab 0px 0px 10px 10px;' : ''
        "
      >
        <!-- <div class="aiBox">
      <span title="选配AI" @click="handle_show_choose_ai()" class="iconfont icon-xuanpeiAI"></span>
    </div> -->
        <div class="seklect">
          <el-select
            v-model="value"
            placeholder="请选择"
            popper-class="seloption"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <LiveNVRPlayer
          :dataSource="dataSource"
          v-if="value === 'webrtc' || value === 'webrtc2'"
          class="live"
        />
        <SRSPlayer :dataSource="dataSource" v-if="value === 'flv'" />
        <hlsPlayer :dataSource="dataSource" v-if="value === 'hls'"></hlsPlayer>
      </Dialog>
    </div>

    <div :style="videoPostion ? containerAIRight : containerAILeft">
      <Dialog
        v-interact="{ el: 3 }"
        title="选择开启AI"
        :visible.sync="visibleAI"
        v-if="visible"
      >
        <AiBooth
          ref="ai-booth"
          :ai="selsectAi"
          :deviceHardId="deviceHardId"
          :dataSource="ai.dataSource"
          selection
          type="1"
        />
      </Dialog>
    </div>
  </div>
</template>

<script>
import Dialog from "@/components/common/dialog";
import LiveNVRPlayer from "@/components/device/player/livenvr";
import SRSPlayer from "@/components/device/player/srs";
import hlsPlayer from "@/components/device/player/hlsPlayer";

import AiBooth from "@/components/device/ai-booth";
import API from "@/api";
export default {
  props: {
    id: {
      type: Number,
      default: "",
    },
    selsectAi: {
      type: String,
      default: "",
    },
    deviceHardId: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "标题",
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    videoPostion: {
      type: Boolean,
      default: true,
    },
    videoPosRight: {
      type: String,
      default: "",
    },
    videoPosLeft: {
      type: String,
      default: "",
    },
    boxShadow: {
      type: Object,
      default: () => {},
    },
  },
  components: { Dialog, LiveNVRPlayer, SRSPlayer, AiBooth, hlsPlayer },
  data() {
    return {
      visible: true,
      visibleAI: false,
      ai: {
        dataSource: [],
        data: null,
      },
      vidDiaPos: "",
      // videoPosRight: `
      //   top: 180px;
      //   left: 580px;
      //   zIndex: 999;
      //   transition: all 300ms linear;
      //   position: absolute;`,
      // videoPosLeft: `
      //   top: 180px;
      //   left: 280px;
      //   zIndex: 999;
      //   transition: all 300ms linear;
      //   position: absolute;`,
      containerAIRight: `
        top: 180px;
        left: 1000px;
        zIndex: 999;
        transition: all 300ms linear;
        position: absolute;`,
      containerAILeft: `
        top: 180px;
        left: 700px;
        zIndex: 999;
        transition: all 300ms linear;
        position: absolute;`,
      options: [
        {
          value: "webrtc",
          label: "webrtc",
        },
        {
          value: "webrtc2",
          label: "推流器",
        },
        {
          value: "hls",
          label: "hls",
        },
        {
          value: "flv",
          label: "flv",
        },
      ],
      value: "webrtc",
    };
  },
  created() {
    let ip = window.location.host;
    if (ip == "201.10.1.253") {
      this.value = "webrtc";
    }
  },
  methods: {
    clickShadow() {
      console.log(213123871263871263);
      this.$emit("isSelectedShdow", this.boxShadow.index);
    },
    async handle_show_choose_ai() {
      try {
        let res = await API.AI.List();
        res = res.data;
        this.ai.dataSource = res.map((item) => ({
          title: item.value,
          id: item.key,
          icon: item.path,
          checked: false,
        }));
        this.visibleAI = true;
      } catch (e) {
        console.error(e);
      }
    },
  },
  watch: {
    visible(val) {
      if (!val) {
        this.$emit("on-viewClose", this.id);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep {
  .videoLog {
    top: inherit;

    .dialog-content {
      .dialog-title {
        height: 28px;
        background: #000000;
        opacity: 0.5;
        background-image: none;

        &::before {
          display: none;
        }

        &::after {
          display: none;
        }

        .dialog-inner-title {
          background-image: none;

          &::before {
            display: none;
          }

          &::after {
            display: none;
          }
        }
      }

      .dialog-body {
        margin: 0 0 5px 0;
      }
    }
  }

  .aiBox {
    position: absolute;
    top: 13px;
    left: 20px;
    color: #fff;
    width: 30px;
    z-index: 2000;
    height: 24px;
    cursor: pointer;

    span {
      font-size: 18px;
    }
  }

  .seklect {
    width: 75px;
    position: absolute;
    top: 11px;
    left: 46px;
    color: #fff;
    z-index: 2000;
    height: 24px;
    cursor: pointer;

    span {
      font-size: 18px;
    }

    .el-input__inner {
      background: rgba(0, 0, 0, 0);
      color: rgba(187, 207, 240, 1);
      height: 24px;
      font-size: 12px;
      padding-left: 5px;
      padding-right: 0px;
      border: none;
    }

    .el-input__suffix {
      .el-input__suffix-inner {
        height: 100%;

        .el-select__caret {
          height: 24px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
