<template>
  <div class="cpt-nest-logger" v-interact="{ resize: [false, true, false, false] }">
    <div class="title-box">
      <div class="title pl20">
        <div class="font">运行监控日志</div>
      </div>
      <div style="display: flex">
        <div class="icon-box" @click="$emit('clear-msg')">
          <span class="iconfont icon-qingchushuju"></span>
        </div>
        <div class="cf ml20 mr10 c70d" @click="$emit('exit')">关闭</div>
      </div>
    </div>
    <div class="ctx-box">
      <div class="list-box">
        <div class="item-box mt7" v-for="(item, index) in list" :key="index">
          <div class="text-box pl100">
            <div class="type-box" :class="{ emerg: item.type === 1, ordinary: item.type === 8 }">
              <span class="type">AUTO</span>
            </div>
            <span class="text" v-html="item.text"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    list() {
      return this.$store.state.mqtt.loggerList
    }
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.yidong {
  left: 52% !important;
}

.cpt-nest-logger {
  position: fixed;
  width: 510px;
  height: 266px;
  top: 45%;
  left: 29%;
  display: flex;
  background: rgba(9, 32, 87, 0.7);
  border-radius: 10px 10px 10px 10px;
  flex-direction: column;

  .liucBox {
    .default {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #afdcff;
      letter-spacing: 1px;
      font-weight: 400;

      .radius {
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #afdcff;
        margin-left: 5px;
      }

      .line {
        height: 0.5px;
        display: inline-block;
        margin-bottom: 4px;
        border-top: 0.2px dashed #afdcff;
      }
    }

    .current {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #43deff;
      letter-spacing: 1px;
      font-weight: 400;

      .line {
        height: 0.5px;
        display: inline-block;
        margin-bottom: 4px;
        border-top: 1px solid #43deff;
        background: #43deff;
      }

      .radius {
        display: inline-block;
        border-radius: 50%;
        margin-left: 5px;
        background: #43deff;
        border: 1px solid #43deff;
      }
    }

    .end {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: rgba(67, 222, 255, 0.6);
      letter-spacing: 1px;
      font-weight: 400;

      .radius {
        display: inline-block;
        border-radius: 50%;
        margin-left: 5px;
        background: rgba(67, 222, 255, 0.7);
      }

      .line {
        height: 0.5px;
        display: inline-block;
        margin-bottom: 4px;
        border: 1px solid #afdcff;
        background: #afdcff;
      }
    }
  }

  .title-box {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    background: linear-gradient(180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.45) 40%,
        #05091a 100%);
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .title {
      display: flex;
      align-items: center;

      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg,
            #e3aa77 0%,
            #f5cda9 38%,
            #f9ecd3 58%,
            #fcdbb1 79%,
            #edb07a 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .icon-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .iconfont {
        font-size: 18px;
        color: #00ffff;
        margin-right: 4px;
      }

      .icon-text {
        font-family: MicrosoftYaHeiUI;
        font-size: 14px;
        color: #ccedff;
        font-weight: 400;
      }
    }
  }

  .ctx-box {
    flex: 1;
    overflow: hidden;

    .list-box {
      height: 100%;
      overflow: auto;
      overflow-x: hidden;
      box-sizing: border-box;

      .item-box {
        display: flex;
        align-items: center;
        color: #fff;

        .text-box {
          display: flex;
          align-items: center;

          .type-box {
            width: 38px;
            height: 20px;
            transform-origin: center;
            transform: scale(0.6);
            padding: 2px 6px;
            border-radius: 30px;
            text-align: center;
            display: flex;
            align-items: center;

            &.ordinary {
              background-color: #298ad3;
            }

            &.emerg {
              background-color: #ff3c3c;
            }

            .type {
              font-size: 10px;
            }
          }

          .text {
            font-size: 12px;
            color: #bacfea;
          }
        }
      }
    }
  }

  .xb {
    transform: rotate(-180deg);
    background: rgba(1, 10, 46, 0.63);
    border-radius: 10px 10px 0 0;
  }
}

.jiantou {
  width: 22px;
  height: 22px;
  transform: rotate(90deg);
  cursor: pointer;
  margin: 0 auto;
}

.chang {
  height: 250px !important;
  top: 233px;
}

.zhaun {
  transform: rotate(270deg) !important;
}

.c70d {
  color: #70daf9;
}
</style>