var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"title":"挂载面板","isShowClose":false,"containerStyle":{
    width: '500px',
    height: '182px',
    top: '410px',
    left: '27px',
    zIndex: 11,
  }}},[_c('MountBooth',{attrs:{"dataSource":_vm.MountData.list},on:{"on-change":(e) => _vm.$emit('on-change', e)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }