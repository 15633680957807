var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-platform-visual"},[_c('UAVTask',{ref:"one",attrs:{"float_videos":_vm.float_videos,"uav_control":_vm.uav_control},on:{"on-location":_vm.handle_location,"on-control":_vm.handle_control,"on-video":_vm.handle_video,"on-report":_vm.handle_report,"upOnList":_vm.upOnList,"chageVdieoPost":_vm.chageVdieoPost}}),(_vm.uavTaskListFlag && _vm.uav_control.stationType == 1)?_c('UAVTaskList',{ref:"uavTaskList",on:{"selfTest":function($event){_vm.selfTestFlag = true}}}):_vm._e(),(_vm.uav_control && _vm.uav_control.deviceHardId)?[_c('UAVDashboard',{attrs:{"batteryData":_vm.batteryData[_vm.uav_control.deviceHardId],"goodsName":_vm.uav_control.goodsName,"data":_vm.uav_data[_vm.uav_control.deviceHardId]},on:{"fn":_vm.control_ws}})]:_vm._e(),(_vm.selected_mount)?[_c('Dialog',{ref:"move",attrs:{"isShowClose":false,"title":_vm.selected_mount.title,"containerStyle":{
        width: `${_vm.uav_width}`,
        top: '266px',
        right: '96px',
        zIndex: 30,
      }}},[_c('div',{staticClass:"movediv",on:{"mouseenter":_vm.mouseDown,"mouseleave":_vm.dragmove}},[_c('div',{staticClass:"iconfont icon-guanbi close",on:{"click":_vm.closeMountDetails}})]),_c(_vm.selected_mount.component,{tag:"component",attrs:{"whachChange":_vm.whachChange},on:{"directive":_vm.handle_mount_directive,"width":_vm.boxWidth}})],1)]:_vm._e(),_vm._l((_vm.float_videos),function(item,index){return _c('FloatPlayer',{key:item.deviceHardId,staticClass:"float_videos_box",attrs:{"id":item.id,"title":item.title,"selsectAi":item.ai,"dataSource":item.dataSource,"deviceHardId":item.deviceHardId,"videoPostion":_vm.videoPostion,"videoPosRight":_vm.videoPosRight[index],"videoPosLeft":_vm.videoPosLeft[index],"boxShadow":_vm.boxShadow[index]},on:{"on-viewClose":_vm.close_video,"isSelectedShdow":_vm.isSelectedShdow}})}),(_vm.showWarning)?_c('Warning',{attrs:{"onLineList":_vm.onLineList},on:{"onclose":function($event){_vm.showWarning = false}}}):_vm._e(),_c('FiexdRight',[_c('template',{slot:"alertMessage"},[_c('div',{staticClass:"w65 h65 cp",on:{"click":function($event){_vm.showWarning = !_vm.showWarning}}},[_c('el-tooltip',{attrs:{"content":"预警","placement":"left","effect":"dark"}},[_c('img',{staticClass:"w65 h65 dib",attrs:{"src":_vm.alertMessage}})])],1)])],2),(
      _vm.uav_control &&
      _vm.uav_control.deviceHardId &&
      _vm.uav_control.goodsName != '小旋风'
    )?_c('FlexRightMenu',{ref:"menuClose",on:{"fn":_vm.control_ws,"on-change":_vm.handle_change_mount}}):_vm._e(),_c('transition',{key:"1",attrs:{"name":"animate__animated dh","enter-active-class":"animate__fadeInUp","leave-active-class":"animate__fadeOutDown"}},[_c('Bottomright')],1),(_vm.loggerFlag)?_c('Logger',{on:{"exit":function($event){_vm.loggerFlag = false}}}):_vm._e(),(_vm.selfTestFlag)?_c('selfTest',{on:{"closeFlag":function($event){_vm.selfTestFlag = false},"takeOff":_vm.takeOff_}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }